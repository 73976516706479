const Chefs=()=>{
    return(
        <>
      
        <div class="page-title-area item-bg4">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="page-title-content">
                            <h2>Team</h2>
                            <ul>
                                <li>
                                    <a href="index.html">Home</a>
                                </li>
                                <li>Team</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <section class="team-area bg-ffffff pt-100 pb-70">
            <div class="container">
                <div class="section-title">
                    <h2>Our Chefs</h2>
                    <p>Meet our skilled chefs who prepare delicious and 
                    healthy meals for you with care and expertise. 
                    Enjoy a culinary journey that delights your taste buds and 
                    nourishes your body.</p>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="single-team">
                            <div class="image">
                                <img src="assets/img/team/team4.jpg" alt="image" />
                            
                                <ul class="social">
                                    <li>
                                        <a href="#" target="_blank">
                                            <i class="lab la-facebook-f"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" target="_blank">
                                            <i class="lab la-twitter"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" target="_blank">
                                            <i class="lab la-instagram"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" target="_blank">
                                            <i class="lab la-linkedin-in"></i>
                                        </a>
                                    </li>
                                </ul>
    
                                <div class="content">
                                    <h3>Justin Roberto</h3>
                                    <span>Sous Chef</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <div class="single-team">
                            <div class="image">
                                <img src="assets/img/team/team5.jpg" alt="image" />
                            
                                <ul class="social">
                                    <li>
                                        <a href="#" target="_blank">
                                            <i class="lab la-facebook-f"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" target="_blank">
                                            <i class="lab la-twitter"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" target="_blank">
                                            <i class="lab la-instagram"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" target="_blank">
                                            <i class="lab la-linkedin-in"></i>
                                        </a>
                                    </li>
                                </ul>
    
                                <div class="content">
                                    <h3>Jamie Oliver</h3>
                                    <span>Kitchen Manager</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <div class="single-team">
                            <div class="image">
                                <img src="assets/img/team/team6.jpg" alt="image" />
                            
                                <ul class="social">
                                    <li>
                                        <a href="#" target="_blank">
                                            <i class="lab la-facebook-f"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" target="_blank">
                                            <i class="lab la-twitter"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" target="_blank">
                                            <i class="lab la-instagram"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" target="_blank">
                                            <i class="lab la-linkedin-in"></i>
                                        </a>
                                    </li>
                                </ul>
    
                                <div class="content">
                                    <h3>Kenneth Linke</h3>
                                    <span>Assistant Manager</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <div class="single-team">
                            <div class="image">
                                <img src="assets/img/team/team7.jpg" alt="image" />
                            
                                <ul class="social">
                                    <li>
                                        <a href="#" target="_blank">
                                            <i class="lab la-facebook-f"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" target="_blank">
                                            <i class="lab la-twitter"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" target="_blank">
                                            <i class="lab la-instagram"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" target="_blank">
                                            <i class="lab la-linkedin-in"></i>
                                        </a>
                                    </li>
                                </ul>
    
                                <div class="content">
                                    <h3>Bella Rose</h3>
                                    <span>Assistant Chef</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <div class="single-team">
                            <div class="image">
                                <img src="assets/img/team/team8.jpg" alt="image" />
                            
                                <ul class="social">
                                    <li>
                                        <a href="#" target="_blank">
                                            <i class="lab la-facebook-f"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" target="_blank">
                                            <i class="lab la-twitter"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" target="_blank">
                                            <i class="lab la-instagram"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" target="_blank">
                                            <i class="lab la-linkedin-in"></i>
                                        </a>
                                    </li>
                                </ul>
    
                                <div class="content">
                                    <h3>Alicia Grace</h3>
                                    <span>Cooking Planner</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <div class="single-team">
                            <div class="image">
                                <img src="assets/img/team/team9.jpg" alt="image" />
                            
                                <ul class="social">
                                    <li>
                                        <a href="#" target="_blank">
                                            <i class="lab la-facebook-f"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" target="_blank">
                                            <i class="lab la-twitter"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" target="_blank">
                                            <i class="lab la-instagram"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" target="_blank">
                                            <i class="lab la-linkedin-in"></i>
                                        </a>
                                    </li>
                                </ul>
    
                                <div class="content">
                                    <h3>Katharine</h3>
                                    <span>Assistant Chef</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      
        </>
    )
}
export default Chefs;