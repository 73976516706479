// import { createSlice } from '@reduxjs/toolkit';
// import axios from 'axios';

// const initialState = {
//   billing: {
//     firstName: '',
//     lastName: '',
//     companyName: '',
//     address: '',
//     city: '',
//     state: '',
//     postcode: '',
//     email: '',
//     phone: '',
//     notes: '',
//   },
//   shipping: {
//     country: '',
//     firstName: '',
//     lastName: '',
//     companyName: '',
//     address: '',
//     city: '',
//     postcode: '',
//     email: '',
//     phone: '',
//   },
//   // Add more fields or nested objects as needed
// };

// const customerSlice = createSlice({
//   name: 'customer',
//   initialState,
//   reducers: {
//     updateBillingField(state, action) {
//       const { field, value } = action.payload;
//       state.billing[field] = value;
//     },
//     updateShippingField(state, action) {
//       const { field, value } = action.payload;
//       state.shipping[field] = value;
//     },
//     clearCustomerData(state) {
//       state.billing = initialState.billing;
//       state.shipping = initialState.shipping;
//     },
//   },
// });

// export const { updateBillingField, updateShippingField, clearCustomerData } = customerSlice.actions;

// export const submitFormData = (formData) => {
//   return async (dispatch) => {
//     try {
//       const response = await axios.post('http://localhost:5000/api/customers/checkout', formData);

//       console.log('Response from server:', response.data);
//       // Dispatch any success actions here if needed
//     } catch (error) {
//       console.error('Error submitting form data:', error);
//       // Dispatch any error actions here if needed
//     }
//   };
// };

// export default customerSlice.reducer;


import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Define the initial state
const initialState = {
  loading: false,
  error: null,
  success: false,
};

// Define the async thunk for submitting form data
export const submitFormData = createAsyncThunk(
  'customer/submitFormData',
  async (formData, { rejectWithValue }) => {

    console.log('AAA',formData);

    try {
      // Make a POST request to the backend API endpoint for checkout
      const response = await axios.post('https://react-ecom.acesoftech.co.in/api/customers/checkout', formData, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      
console.log(response);
      // Return the response data
      return response.data;
    } catch (error) {
      // Return the error message if request fails
      return rejectWithValue(error.message);
    }
  }
);

// Define the customer slice
const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    // Additional reducers can be defined here if needed
  },
  extraReducers: (builder) => {
    builder
      // Handle pending state when form submission is in progress
      .addCase(submitFormData.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      // Handle fulfilled state when form submission is successful
      .addCase(submitFormData.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
        state.success = true;
      })
      // Handle rejected state when form submission fails
      .addCase(submitFormData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Payload contains the error message
        state.success = false;
      });
  },
});

// Export the action creators and reducer
export const { /* additional action creators */ } = customerSlice.actions;
export default customerSlice.reducer;
