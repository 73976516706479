import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios'; // Assuming you are using axios for HTTP requests
const API='https://react-ecom.acesoftech.co.in/api/bookatable'
export const fetchBookings = createAsyncThunk(
  'bookings/fetchBookings',
  async () => {
    const response = await axios.get(API+'/bookings');
    return response.data;
  }
);

export const addBooking = createAsyncThunk(
  'bookings/addBooking',
  async (bookingData) => {
    const response = await axios.post(API+'/bookings', bookingData);
    return response.data;
  }
);

export const bookingsSlice = createSlice({
  name: 'bookings',
  initialState: {
    bookings: [],
    status: 'idle',
    error: null
  },
  reducers: {
    // You can keep your synchronous reducer here if needed
    // bookTable: (state, action) => {
    //   state.bookings.push(action.payload);
    // }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBookings.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchBookings.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.bookings = action.payload;
      })
      .addCase(fetchBookings.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(addBooking.fulfilled, (state, action) => {
        state.bookings.push(action.payload);
      });
  }
});

export default bookingsSlice.reducer;
