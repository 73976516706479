// store.js
import { configureStore } from '@reduxjs/toolkit';
import productSlice from '../reducers/productSlice';
import globalSlice from '../reducers/globalSlice';
import cartSlice from '../reducers/cartSlice'; // Import cartSlice
import customerSlice from '../reducers/customerSlice';
import blogSlice from '../reducers/blogSlice';
const store = configureStore({
  reducer: {
    products: productSlice,
    global: globalSlice,
    cart: cartSlice, // Add cartSlice to the reducers
    customer:customerSlice,
    blogs: blogSlice
  }
});

export default store;
