import React, { useEffect,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams,Link } from 'react-router-dom'; // Import useParams hook to get the product ID from the URL
import { fetchSingleProduct } from '../reducers/productSlice';
import { addItem } from '../reducers/cartSlice'; // Import addItem action creator

const SingleProduct = () => {
  const { id } = useParams(); // Get the product ID from the URL params
  const dispatch = useDispatch();
  const singleProduct = useSelector(state => state.products.singleProduct);
 // Get the single product from the store
  const imagePath = useSelector(state => state.global.imagePath); // Get the imagePath from the global state
  const [quantity, setQuantity] = useState(1);
//   console.log('SSSS',singleProduct?.productName);
const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  useEffect(() => {
    // Fetch the single product data when the component mounts
    dispatch(fetchSingleProduct(id));
  }, [dispatch, id]); // Dependency array to ensure the effect runs only when the product ID changes


  const addToCart = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const productData = {
      _id: formData.get('_id'),
      productName: formData.get('productName'),
      description: formData.get('description'),
      price: formData.get('price'),
      images: Array.from(formData.keys())
        .filter(key => key.startsWith('image-'))
        .map(key => formData.get(key)),
      quantity: parseInt(formData.get('quantity'), 10) || 1 // Parse as integer with default value of 1
    };
    dispatch(addItem(productData));
    setShowSuccessMessage(true); // Show success message after adding the product
  };
  

  return(
        <>
         {singleProduct ? ( // Check if singleProduct data is available
<section class="product-details-area pt-100 pb-70">
<div class="container">
    <div class="row">
        <div class="col-lg-8">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="product-details-images">

                    {singleProduct.product_images.map((image, index) => (
                    <img key={index} src={imagePath + image} alt={`Product ${index + 1}`} style={{  marginRight: '5px' }} />
                  ))}
                        {/* <img src={imagePath + singleProduct.image} alt={`Product Price`} /> */}
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="product-details-desc">
                      <h3>{singleProduct.productName}</h3>
                        <div class="price">
                            <span class="new-price">${singleProduct.price}</span>
                            {/* <span class="old-price">$652.00</span> */}
                        </div>
                        <div class="product-review">
                            <div class="rating">
                                <i class="las la-star"></i>
                                <i class="las la-star"></i>
                                <i class="las la-star"></i>
                                <i class="las la-star"></i>
                                <i class="las la-star"></i>
                            </div>
                            <a href="#" class="rating-count">3 reviews</a>
                        </div>
                        <p>
                        {singleProduct.description}
                        </p>
                        <form onSubmit={addToCart}>
                        <input type="hidden" name="_id" value={singleProduct._id} />
                        <input type="hidden" name="productName" value={singleProduct.productName} />
                        <input type="hidden" name="description" value={singleProduct.description} />
                        <input type="hidden" name="price" value={singleProduct.price} />
                        {singleProduct.product_images.map((image, index) => (
                    
                        <input key={index} type="hidden" name={`image-${index}`} value={imagePath + image} />

                  ))}


                        <div class="product-add-to-cart">
                            <div class="input-counter">
                                <span class="minus-btn">
                                    <i class="las la-minus"></i>
                                </span>
                                <input
    type="number"
    min="1"
    value={quantity}
    onChange={(e) => setQuantity(e.target.value)}
/>
                                <span class="plus-btn">
                                    <i class="las la-plus"></i>
                                </span>
                            </div>
                            <button type="submit" class="default-btn">
                                Add to cart
                                <span></span>
                            </button>
                            <div className='added'>
                            {showSuccessMessage && (
        <p style={{ color: 'green', fontWeight: 'bold' }}>
        Product Added Successfully.<span><Link to="/cart">View Cart</Link></span>
        </p>
      )}
                            </div>
                        </div>
                         </form>

                        <div class="buy-checkbox-btn">
                            <div class="item">
                                <input class="inp-cbx" id="cbx" type="checkbox" />
                                <label class="cbx" for="cbx">
                                    <span>
                                        <svg width="12px" height="10px" viewbox="0 0 12 10">
                                            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                        </svg>
                                    </span>
                                    {/* <span>I agree with the terms and conditions</span> */}
                                </label>
                            </div>
                            {/* <div class="item">
                                <a href="#" class="btn btn-light">Buy it now!</a>
                            </div> */}
                        </div>

                        <div class="products-share">
                            <ul class="social">
                                <li><span>Share:</span></li>
                                <li>
                                    <a href="#" class="facebook" target="_blank"><i class="lab la-facebook-f"></i></a>
                                </li>
                                <li>
                                    <a href="#" class="twitter" target="_blank"><i class="lab la-twitter"></i></a>
                                </li>
                                <li>
                                    <a href="#" class="linkedin" target="_blank"><i class="lab la-linkedin-in"></i></a>
                                </li>
                                <li>
                                    <a href="#" class="instagram" target="_blank"><i class="lab la-instagram"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab products-details-tab">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <ul class="tabs">
                            <li>
                                <a href="#">
                                    <div class="dot"></div> 
                                    Description
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <div class="dot"></div> 
                                    Reviews
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="tab_content">
                            <div class="tabs_item">
                                <div class="products-details-tab-content">
                                    <p>{singleProduct.description}</p>
                                </div>
                            </div>

                            <div class="tabs_item">
                                <div class="products-details-tab-content">
                                    <div class="product-review-form">
                                        <h3>Customer Reviews</h3>
                                        <div class="review-title">
                                            <div class="rating">
                                                <i class="las la-star"></i>
                                                <i class="las la-star"></i>
                                                <i class="las la-star"></i>
                                                <i class="las la-star"></i>
                                                <i class="lar la-star"></i>
                                            </div>
                                            <p>Based on 3 reviews</p>
                                            <a href="#" class="default-btn">
                                                Write a Review
                                            </a>
                                        </div>
                                        <div class="review-comments">
                                            <div class="review-item">
                                                <div class="rating">
                                                    <i class="las la-star"></i>
                                                    <i class="las la-star"></i>
                                                    <i class="las la-star"></i>
                                                    <i class="las la-star"></i>
                                                    <i class="lar la-star"></i>
                                                </div>
                                                <h3>Good</h3>
                                                <span><strong>Admin</strong> on <strong>Sep 21, 2021</strong></span>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.</p>
                                                <a href="#" class="review-report-link">Report as Inappropriate</a>
                                            </div>
                                            <div class="review-item">
                                                <div class="rating">
                                                    <i class="las la-star"></i>
                                                    <i class="las la-star"></i>
                                                    <i class="las la-star"></i>
                                                    <i class="las la-star"></i>
                                                    <i class="lar la-star"></i>
                                                </div>
                                                <h3>Good</h3>
                                                <span><strong>Admin</strong> on <strong>Sep 21, 2021</strong></span>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.</p>
                                                <a href="#" class="review-report-link">Report as Inappropriate</a>
                                            </div>
                                            <div class="review-item">
                                                <div class="rating">
                                                    <i class="las la-star"></i>
                                                    <i class="las la-star"></i>
                                                    <i class="las la-star"></i>
                                                    <i class="las la-star"></i>
                                                    <i class="lar la-star"></i>
                                                </div>
                                                <h3>Good</h3>
                                                <span><strong>Admin</strong> on <strong>Sep 21, 2021</strong></span>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.</p>
                                                <a href="#" class="review-report-link">Report as Inappropriate</a>
                                            </div>
                                        </div>
                                        <div class="review-form">
                                            <h3>Write a Review</h3>
                                            <form>
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6">
                                                        <div class="form-group">
                                                            <input type="text" id="name" name="name" placeholder="Enter your name" class="form-control" />
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                        <div class="form-group">
                                                            <input type="email" id="email" name="email" placeholder="Enter your email" class="form-control" />
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-md-12">
                                                        <div class="form-group">
                                                            <input type="text" id="review-title" name="review-title" placeholder="Enter your review a title" class="form-control" />
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-md-12">
                                                        <div class="form-group">
                                                            <textarea name="review-body" id="review-body" cols="30" rows="7" placeholder="Write your comments here" class="form-control"></textarea>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-md-12">
                                                        <button type="submit" class="default-btn">
                                                            Submit Review
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div class="related-shop">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="pizza-products-item">
                            <div class="image">
                                <a href="#">
                                   <img src="assets/img/pizza-products/products1.jpg" alt="image" />
                                </a>

                                <div class="offer-text">
                                    <h4>25%</h4>
                                    <span>Off</span>
                                </div>
                            </div>
                            <div class="content">
                                <h3>
                                    <a href="#">Chicken Mushroom Pizza</a>
                                </h3>
                                <span>$22.00</span>

                                <div class="products-btn">
                                    <a href="cart.html" class="default-btn">Add To Cart</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="pizza-products-item">
                            <div class="image">
                                <a href="#">
                                    <img src="assets/img/pizza-products/products2.jpg" alt="image" />
                                </a>

                                <div class="offer-text">
                                    <h4>25%</h4>
                                    <span>Off</span>
                                </div>
                            </div>
                            <div class="content">
                                <h3>
                                    <a href="#">Pizza al padellino</a>
                                </h3>
                                <span>$22.00</span>

                                <div class="products-btn">
                                    <a href="cart.html" class="default-btn">Add To Cart</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="pizza-products-item">
                            <div class="image">
                                <a href="#">
                                    <img src="assets/img/pizza-products/products3.jpg" alt="image" />
                                </a>

                                <div class="offer-text">
                                    <h4>25%</h4>
                                    <span>Off</span>
                                </div>
                            </div>
                            <div class="content">
                                <h3>
                                    <a href="#">Pizza alla pala</a>
                                </h3>
                                <span>$22.00</span>

                                <div class="products-btn">
                                    <a href="cart.html" class="default-btn">Add To Cart</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="pizza-products-item">
                            <div class="image">
                                <a href="#">
                                    <img src="assets/img/pizza-products/products4.jpg" alt="image" />
                                </a>

                                <div class="offer-text">
                                    <h4>25%</h4>
                                    <span>Off</span>
                                </div>
                            </div>
                            <div class="content">
                                <h3>
                                    <a href="#">Grilled Pizza</a>
                                </h3>
                                <span>$22.00</span>

                                <div class="products-btn">
                                    <a href="cart.html" class="default-btn">Add To Cart</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>

        <div class="col-lg-4 col-md-12">
            {/* <aside class="widget-area" id="secondary">
                <section class="widget widget_search">
                    <form class="search-form search-top">
                        <label>
                            <span class="screen-reader-text">Search for:</span>
                            <input type="search" class="search-field" placeholder="Search..." />
                        </label>
                        <button type="submit">
                            <i class="flaticon-loupe"></i>
                        </button>
                    </form>
                </section>
                
                <section class="widget widget_vizo_posts_thumb">
                    <h3 class="widget-title">Popular Posts</h3>
                    <article class="item">
                        <a href="#" class="thumb">
                            <span class="fullimage cover bg1" role="img"></span>
                        </a>
                        <div class="info">
                            <time class="2021-06-30">June 10, 2021</time>
                            <h4 class="title usmall">
                                <a href="#">Restaurant In This City Right Now So We Scour The City</a>
                            </h4>
                        </div>
                    </article>

                    <article class="item">
                        <a href="#" class="thumb">
                            <span class="fullimage cover bg2" role="img"></span>
                        </a>
                        <div class="info">
                            <time class="2021-06-30">June 21, 2021</time>
                            <h4 class="title usmall">
                                <a href="#">Better Hot Drink Elegant You Can Order By Online</a>
                            </h4>
                        </div>
                    </article>

                    <article class="item">
                        <a href="#" class="thumb">
                            <span class="fullimage cover bg3" role="img"></span>
                        </a>
                        <div class="info">
                            <time class="2021-06-30">June 30, 2021</time>
                            <h4 class="title usmall">
                                <a href="#">Role Of Genetics In Treating Low-Grade Glioma</a>
                            </h4>
                        </div>
                    </article>
                </section>

                <section class="widget widget_categories">
                    <h3 class="widget-title">Categories</h3>
                    <ul>
                        <li>
                            <a href="#">The Pizza Box</a>
                        </li>
                        <li>
                            <a href="#">Oven-Baked Pizzas and Calzones</a>
                        </li>
                        <li>
                            <a href="#">Sugar and Slice Pizza Shop</a>
                        </li>
                        <li>
                            <a href="#">Sterling Pan Pizza Co.</a>
                        </li>
                        <li>
                            <a href="#">Stakeout Pizza</a>
                        </li>
                        <li>
                            <a href="#">Rosa's Italian Pizzeria</a>
                        </li>
                        <li>
                            <a href="#">Hot Spot Pizza Shop</a>
                        </li>
                        <li>
                            <a href="#">Frankie's Fried Pizza</a>
                        </li>
                    </ul>
                </section>
                
                <section class="widget widget_tag_cloud">
                    <h3 class="widget-title">Tags</h3>
                    <div class="tagcloud section-bottom">
                        <a href="#">Tomato Poha</a>
                        <a href="#">Salad</a>
                        <a href="#">Mineral Water</a>
                        <a href="#">Kebab</a>
                        <a href="#">Fish Curry</a>
                        <a href="#">Chicken Curry</a>
                        <a href="#">Rice</a>
                        <a href="#">Curd Upma</a>
                        <a href="#">Chana Kulcha</a>
                        <a href="#">Breakfast</a>
                        <a href="#">Lunch</a>
                        <a href="#">Dinner</a>
                        <a href="#">Coffee</a>
                        <a href="#">Snacks</a>
                        <a href="#">Menu</a>
                    </div>
                </section>
            </aside> */}
        </div>
    </div>
</div>
</section>
) : (
        <p>Loading...</p> // Render a loading message while fetching data
      )}
    </>
  );
};
export default SingleProduct;