import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { emptyCart } from '../reducers/customerSlice';

const Payment = () => { 

  return (
    <>
      <section className="checkout-area ptb-100">
        <div className="container text-center">
        <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
      <input type="hidden" name="business" value="payment@duckchiken.store" />
      <input type="hidden" name="cmd" value="_xclick" />
      <input type="hidden" name="item_name" value="Product Name" />
      <input type="hidden" name="amount" value="10.00" />
      <input type="hidden" name="currency_code" value="USD" />
      <input type="hidden" name="return" value="http://example.com/success" />
      <input type="hidden" name="cancel_return" value="http://example.com/cancel" />
      <button type="submit">
        <img src="https://www.paypalobjects.com/en_US/i/btn/btn_buynow_LG.gif" border="0" alt="PayPal - The safer, easier way to pay online" />
      </button>
      <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1" />
    </form>
        </div>
      </section>
    </>
  );
}

export default Payment;
