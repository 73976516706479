import React, { useState,useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import { submitFormData } from '../reducers/customerSlice';
import countryData from '../country_data';

const Checkout = () => {
  const dispatch = useDispatch();
  const cartItems = useSelector(state => state.cart.items);
  const [showShipping, setShowShipping] = useState(false); // State variable for toggling shipping address section
  const navigate = useNavigate(); // Initialize useNavigate hook
  const [orderInfo, setOrderInfo] = useState();

  const [formDatas, setFormDatas] = useState({
    billing_firstName: '',
    billing_lastName: '',
    billing_companyName: '',
    billing_address: '',
    billing_city: '',
    billing_state: '',
    billing_postcode: '',
    billing_email: '',
    billing_phone: '',
    // create_an_account: false
});

const [errors, setErrors] = useState({});

const validateForm = () => {
    const errorsCopy = {}; // Create a copy of errors object

    // Perform validation
    if (!formDatas.billing_email || !/\S+@\S+\.\S+/.test(formDatas.billing_email)) {
        errorsCopy['billing_email'] = 'Invalid email address.';
    }

    if (!formDatas.billing_firstName || formDatas.billing_firstName.trim() === '') {
        errorsCopy['billing_firstName'] = 'First name is required.';
    }

    if (!formDatas.billing_lastName || formDatas.billing_lastName.trim() === '') {
        errorsCopy['billing_lastName'] = 'Last name is required.';
    }

    if (!formDatas.billing_address || formDatas.billing_address.trim() === '') {
        errorsCopy['billing_address'] = 'Billing address is required.';
    }

    if (!formDatas.billing_city || formDatas.billing_city.trim() === '') {
        errorsCopy['billing_city'] = 'Billing city name is required.';
    }

    if (!formDatas.billing_state || formDatas.billing_state.trim() === '') {
        errorsCopy['billing_state'] = 'Billing state name is required.';
    }

    if (!formDatas.billing_postcode || formDatas.billing_postcode.trim() === '') {
        errorsCopy['billing_postcode'] = 'Billing postcode is required.';
    }

    if (!formDatas.billing_phone || formDatas.billing_phone.trim() === '') {
        errorsCopy['billing_phone'] = 'Phone number is required.';
    } else if (!/^\d{10}$/.test(formDatas.billing_phone)) {
        errorsCopy['billing_phone'] = 'Phone number must be 10 digits.';
    }

    setErrors(errorsCopy); // Update errors state with new error messages

    // Return true if no errors, false otherwise
    return Object.keys(errorsCopy).length === 0;
};


const handleChange = (e) => {
    const { name, value } = e.target;
    setFormDatas({
        ...formDatas,
        [name]: value
    });

    // Perform validation
    validateForm();
};





  
  
  // Calculate total price
  const totalPrice = cartItems.reduce((total, item) => total + (item.price * item.quantity), 0);
 
  useEffect(() => {
    //const totalPrice = cartItems.reduce((total, item) => total + (item.price * item.quantity), 0);
    if (totalPrice === 0) {
        navigate('/cart'); // Redirect to cart page
    }
}, [cartItems, navigate]);
//   // Function to handle quantity change
//   const handleQuantityChange = (e, itemId) => {
//     const newQuantity = parseInt(e.target.value);
//     dispatch(updateItemQuantity({ id: itemId, quantity: newQuantity }));
//   };

//   // Function to handle item removal
//   const handleRemoveItem = (itemId) => {
//     dispatch(removeItem(itemId));
//   };

  // Function to toggle shipping address section
  const handleToggleShipping = () => {
    setShowShipping(!showShipping);
  };

const handleSubmit = async (e) => {
    e.preventDefault();


const isValid = validateForm();

 if (!isValid) {
        // If form is not valid, prevent form submission
        return;
    }
    // Get the form element
    const formElement = document.getElementById('checkout_form');
  
    // Check if form is valid
    if (!formElement.checkValidity()) {
      // If form is invalid, trigger HTML5 form validation
      formElement.reportValidity();
      return;
    }
  
    // Create FormData objects for customer details and cart details
    const customerData = new FormData(formElement);
    const cartData = new FormData();
  
    // Retrieve cart details from the Redux store
    const cart = cartItems;
  
    // Add cart details to the FormData object
    cartData.append('cartDetails', JSON.stringify(cart));
  
    // Combine customer and cart data into a single FormData object
    const finalData = new FormData();
  
    // Append customer details
    Object.entries(Object.fromEntries(customerData)).forEach(([key, value]) => {
      finalData.append(key, value);
    });
  
    // Append cart details
    Object.entries(Object.fromEntries(cartData)).forEach(([key, value]) => {
      finalData.append(key, value);
    });
  
    try {
      // Dispatch action to submit form data
      const result = await dispatch(submitFormData(finalData));
  
      // Check if the result contains the expected payload structure
      if (result && result.payload && result.payload.order && result.payload.order._id) {
        const orderId = result.payload.order._id;
  
        // Set order ID and success message in session storage
        sessionStorage.setItem('orderId', orderId);
        sessionStorage.setItem('successMessage', 'Order placed successfully');
  
        // Navigate to Thank You page
        setTimeout(() => {
          navigate('/payment'); // Navigate to Thank You page
        }, 3000);
      } else {
        console.error('Error placing order: Invalid payload structure');
        // Handle error if payload structure is invalid
      }
    } catch (error) {
      console.error('Error placing order:', error);
      // Handle error if order submission fails
    }
  };
  

  return (
    <>
    <style>{`
        .error-message {
            color: red;
        }
    `}
    
    </style>
    <section class="checkout-area ptb-100">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <div class="user-actions">
                            <i class="las la-external-link-alt"></i>
                            <span>Returning customer? 
                                <a href="login.html">Click here to login</a>
                            </span>
                        </div>
                    </div>
                </div>
                <form onSubmit={handleSubmit} id='checkout_form'>
                    <div class="row">
                        <div class="col-lg-6 col-md-12">
                        <div class="billing-details" id="billing-details">
    <h3 class="title">Billing Details</h3>
    <div class="row">
        <div class="col-lg-12 col-md-12">
            <div class="form-group">
                <label for="billing_country">Country 
                    <span class="required">*</span>
                </label>
                <div class="select-box">
                <select name="billing_country" id="billing_country" className="form-control">
                    {countryData.map(country => (
                        <option key={country.code} value={country.code} selected={country.name === 'New Zealand'}>
                        {country.name}
                        </option>
                    ))}
    </select>

                </div>
            </div>
        </div>


        <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                            <label htmlFor="billing_firstName">First Name <span className="required">*</span></label>
                            <input type="text" id="billing_firstName" name="billing_firstName" className="form-control" onChange={handleChange} value={formDatas.billing_firstName} />
                            {errors.billing_firstName && <span className="error-message">{errors.billing_firstName}</span>}
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                            <label htmlFor="billing_lastName">Last Name <span className="required">*</span></label>
                            <input type="text" id="billing_lastName" name="billing_lastName" className="form-control" onChange={handleChange} value={formDatas.billing_lastName} />
                            {errors.billing_lastName && (
                            <span className="error-message">{errors.billing_lastName}</span>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                            <label htmlFor="billing_companyName">Company Name</label>
                            <input type="text" id="billing_companyName" name="billing_companyName" className="form-control" onChange={handleChange} value={formDatas.billing_companyName} />
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-6">
                        <div className="form-group">
                            <label htmlFor="billing_address">Address <span className="required">*</span></label>
                            <input type="text" id="billing_address" name="billing_address" className="form-control" onChange={handleChange} value={formDatas.billing_address} />
                
                            {errors.billing_address && (
                            <span className="error-message">{errors.billing_address}</span>
                            )}
                        </div>


                    </div>
                    <div className="col-lg-12 col-md-6">
                        <div className="form-group">
                            <label htmlFor="billing_city">Town / City <span className="required">*</span></label>
                            <input type="text" id="billing_city" name="billing_city" className="form-control" onChange={handleChange} value={formDatas.billing_city} />
                            {errors.billing_city && (
                            <span className="error-message">{errors.billing_city}</span>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                            <label htmlFor="billing_state">State / County <span className="required">*</span></label>
                            <input type="text" id="billing_state" name="billing_state" className="form-control" onChange={handleChange} value={formDatas.billing_state} />
                            {errors.billing_state && (
                            <span className="error-message">{errors.billing_state}</span>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                            <label htmlFor="billing_postcode">Postcode / Zip <span className="required">*</span></label>
                            <input type="text" id="billing_postcode" name="billing_postcode" className="form-control" onChange={handleChange} value={formDatas.billing_postcode} />
                            {errors.billing_postcode && (
                            <span className="error-message">{errors.billing_postcode}</span>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                            <label htmlFor="billing_email">Email Address <span className="required">*</span></label>
                            <input type="email" id="billing_email" name="billing_email" className="form-control" onChange={handleChange} value={formDatas.billing_email} />
                            {errors.billing_email && (
                            <span className="error-message">{errors.billing_email}</span>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                            <label htmlFor="billing_phone">Phone <span className="required">*</span></label>
                            <input type="text" id="billing_phone" name="billing_phone" className="form-control" onChange={handleChange} value={formDatas.billing_phone} />
                            {errors.billing_phone && <span className="error-message">{errors.billing_phone}</span>}
                        </div>
                    </div>

        <div class="col-lg-12 col-md-12">
            <div class="form-check">
                <input type="checkbox" class="form-check-input" id="create_an_account" />
                <label class="form-check-label" for="create_an_account">Create an account?</label>
            </div>
        </div>

        <div class="col-lg-12 col-md-12">
            <div class="form-check">
            <input type="checkbox" className="form-check-input" id="ship_different_address" onChange={handleToggleShipping} />
                <label class="form-check-label" for="ship_different_address">Ship to a different address?</label>
            </div>
        </div>
        <div class="col-lg-12 col-md-12">
            <div class="form-group">
                <textarea name="billing_notes" id="billing_notes" cols="30" rows="5" placeholder="Order Notes" class="form-control"></textarea>
            </div>
        </div>

        
    </div>
</div>


<div class="billing-details" id="shipping-address" style={{ display: showShipping ? 'block' : 'none' }}>
    <h3 class="title">Shipping Details</h3>
    <div class="row">
        <div class="col-lg-12 col-md-12">
            <div class="form-group">
                <label for="shipping_country">Country 
                    <span class="required">*</span>
                </label>
                <div class="select-box">
             <select name="shipping_country" id="shipping_country" class="form-control">
        
             {countryData.map(country => (
            <option key={country.code} value={country.code}>{country.name}</option>
             ))}
             </select>
                    
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6">
            <div class="form-group">
                <label for="shipping_firstName">First Name 
                    <span class="required">*</span>
                </label>
                <input type="text" id="shipping_firstName" name="shipping_firstName" class="form-control" />
            </div>
        </div>
        <div class="col-lg-6 col-md-6">
            <div class="form-group">
                <label for="shipping_lastName">Last Name 
                    <span class="required">*</span>
                </label>
                <input type="text" id="shipping_lastName" name="shipping_lastName" class="form-control" />
            </div>
        </div>
        <div class="col-lg-12 col-md-12">
            <div class="form-group">
                <label for="shipping_companyName">Company Name</label>
                <input type="text" id="shipping_companyName" name="shipping_companyName" class="form-control" />
            </div>
        </div>
        <div class="col-lg-12 col-md-6">
            <div class="form-group">
                <label for="shipping_address">Address 
                    <span class="required">*</span>
                </label>
                <input type="text" id="shipping_address" name="shipping_address" class="form-control" />
            </div>
        </div>
        <div class="col-lg-12 col-md-6">
            <div class="form-group">
                <label for="shipping_city">Town / City 
                    <span class="required">*</span>
                </label>
                <input type="text" id="shipping_city" name="shipping_city" class="form-control" />
            </div>
        </div>
     
        <div class="col-lg-6 col-md-6">
            <div class="form-group">
                <label for="shipping_postcode">Postcode / Zip 
                    <span class="required">*</span>
                </label>
                <input type="text" id="shipping_postcode" name="shipping_postcode" class="form-control" />
            </div>
        </div>
        <div class="col-lg-6 col-md-6">
            <div class="form-group">
                <label for="shipping_email">Email Address 
                    <span class="required">*</span>
                </label>
                <input type="email" id="shipping_email" name="shipping_email" class="form-control" />
            </div>
        </div>
        <div class="col-lg-6 col-md-6">
            <div class="form-group">
                <label for="shipping_phone">Phone 
                    <span class="required">*</span>
                </label>
                <input type="text" id="shipping_phone" name="shipping_phone" class="form-control" />
            </div>
        </div>
    </div>
</div>




                        </div>
                        <div class="col-lg-6 col-md-12">
  <div class="order-details">
    <h3 class="title">Your Order</h3>
    <div class="order-table table-responsive">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th scope="col">Product Name</th>
            <th scope="col">Total</th>
          </tr>
        </thead>
        <tbody>
          {cartItems.map(item => (
            <tr key={item.id}>
              <td class="product-name">
                <a href="#">{item.productName}</a>
              </td>
              <td class="product-total">
                <span class="subtotal-amount">${item.price * item.quantity}</span>
              </td>
            </tr>
          ))}
          <tr>
            <td class="total-price">
              <span>Order Total</span>
            </td>
            <td class="product-subtotal">
              <span class="subtotal-amount">${totalPrice}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="payment-box">
      <div class="payment-method">
        {/* <p>
          <input type="radio" id="direct-bank-transfer" name="radio-group" checked />
          <label for="direct-bank-transfer">Direct Bank Transfer</label>
          Make your payment directly into our bank account. Please use your Order ID as the payment reference. Your order will not be shipped until the funds have cleared in our account.
        </p> */}
        <p>
        <span>Make payment using Paypal, the easiest way of payment.</span>
          <input type="radio" id="paypal" name="radio-group" checked />
          <label for="paypal">PayPal</label>
        </p>
       
      </div>
      <button type="submit" class="default-btn order-btn">
        Place Order
      </button>
    </div>
  </div>
</div>

                    </div>
                </form>
            </div>
        </section>
    </>
  );
}

export default Checkout;
