import React, { useState } from 'react';

const Faqs = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
      setActiveIndex(activeIndex === index ? null : index);
    };
    // JSON data
const faqData = [
    {
      "question": "What is the opening and closing time of your restaurant?",
      "answer": "Our restaurant opens at 4 AM in the morning and closes at 2 AM at night."
    },
    {
      "question": "What if I want to cancel a booked table? Is it refundable?",
      "answer": "It is refundable if you cancel before 24 hours."
    },
    {
      "question": "If I ordered the food, how much time does it take to reach?",
      "answer": "Generally, the food is delivered within 3 hours."
    },
    {
      "question": "What if there is any problem with the food?",
      "answer": "If there is any problem with the food, your money will be refunded, or fresh food will be delivered."
    },
    {
      "question": "My question is not listed here, what should I do?",
      "answer": "Please contact us, and we will try to answer as soon as possible."
    }
  ];
    return (
    <>
       
        <div class="page-title-area">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="page-title-content">
                            <h2>FAQ</h2>
                            <ul>
                                <li>
                                    <a href="index.html">Home</a>
                                </li>
                                <li>FAQ</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      
        <section id="faq" class="faq-area ptb-100">
            <div class="container">
                <div class="section-title">
                    <h2>Asked Questions</h2>
                    <p>
                    We have tried our best to list the possible questios and answers. Below 
                    are the list of FAQs.
                    </p>
                </div>
                <div class="faq-accordion">
        <ul class="accordion">
          {faqData.map((item, index) => (
            <li class="accordion-item" key={index}>
              <a
                class={`accordion-title ${activeIndex === index ? 'active' : ''}`}
                href="javascript:void(0)"
                onClick={() => toggleAccordion(index)}
              >
                <i class="las la-plus"></i>
                {item.question}
              </a>
              <p class={`accordion-content ${activeIndex === index ? 'show' : ''}`}>
                {item.answer}
              </p>
            </li>
          ))}
        </ul>
      </div>
            </div>
        </section>
       
        <div class="faq-contact-area ptb-100">
            <div class="container">
                <div class="section-title">
                    <h2>Do You Have Any Questions?</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
    
                <div class="faq-contact-form">
                    <form id="contactForm">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="text" name="name" id="name" class="form-control" required data-error="Please enter your name" placeholder="Name" />
                                    <div class="help-block with-errors"></div>
                                </div>
                            </div>
    
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="email" name="email" id="email" class="form-control" required data-error="Please enter your email" placeholder="Email" />
                                    <div class="help-block with-errors"></div>
                                </div>
                            </div>
    
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="text" name="phone_number" id="phone_number" required data-error="Please enter your number" class="form-control" placeholder="Phone" />
                                    <div class="help-block with-errors"></div>
                                </div>
                            </div>
    
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="text" name="msg_subject" id="msg_subject" class="form-control" required data-error="Please enter your subject" placeholder="Subject" />
                                    <div class="help-block with-errors"></div>
                                </div>
                            </div>
    
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea name="message" class="form-control" id="message" cols="30" rows="6" required data-error="Write your message" placeholder="Your Message"></textarea>
                                    <div class="help-block with-errors"></div>
                                </div>
                            </div>
    
                            <div class="col-lg-12 col-md-12">
                                <div class="send-btn">
                                    <button type="submit" class="default-btn">
                                        Send Message
                                        <span></span>
                                    </button>
                                </div>
                                <div id="msgSubmit" class="h3 text-center hidden"></div>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        

    </>
        )
    }
    export default Faqs;