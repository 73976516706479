import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import Home from './components/Home';
import SingleProduct from './components/SingleProduct';
import Cart from './components/Cart';
import Checkout from './components/Checkout';
import ThankYou from './components/ThankYou';
import About from './components/pages/About';
import Contact from './components/pages/Contact';
import Shop from './components/Shop';
import BookATable from './components/pages/BookATable';
import Chefs from './components/pages/Chefs';
import Gallery from './components/pages/Gallery';
import Faqs from './components/pages/Faqs';
import Register from './components/pages/Register';
import Login from './components/pages/Login';
import Terms from './components/pages/Terms';
import Privacy from './components/pages/Privacy';
import BlogDetails from './components/pages/blogDetails';
import Payment from './components/Payment';


function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/product/:id" element={<SingleProduct />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/thank-you" element={<ThankYou />} />

        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/book-a-table" element={<BookATable />} />
        <Route path="/chefs" element={<Chefs />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/faqs" element={<Faqs />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/payment" element={<Payment />} />

        <Route path="/blog-details/:blogId" element={<BlogDetails />} />


        {/* Add more routes as needed */}
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
