import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addBooking } from '../../reducers/bookingsSlice';

const BookATable = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        time: '',
        persons: '',
        date: new Date()
    });

    const [bookingSuccess, setBookingSuccess] = useState(false);
    const [error, setError] = useState(null);
    const [formErrors, setFormErrors] = useState({});

    const dispatch = useDispatch();

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.id]: e.target.value });
    };

    const handleDateChange = (date) => {
        setFormData({ ...formData, date });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const errors = validateForm(formData);
        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            return;
        }

        try {
            await dispatch(addBooking(formData));
            setBookingSuccess(true);
            setError(null);
            setFormErrors({});
        } catch (err) {
            setBookingSuccess(false);
            setError(err.message);
        }

        // Clear form after submission if needed
        setFormData({
            name: '',
            email: '',
            phone: '',
            time: '',
            persons: '',
            date: new Date()
        });
    };

    const validateForm = (formData) => {
        const errors = {};
        if (!formData.name.trim()) {
            errors.name = 'Name is required';
        }
        if (!formData.email.trim()) {
            errors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            errors.email = 'Invalid email address';
        }
        if (!formData.phone.trim()) {
            errors.phone = 'Phone number is required';
        }
        if (!formData.time.trim()) {
            errors.time = 'Please select a time';
        }
        if (!formData.persons.trim()) {
            errors.persons = 'Please select number of persons';
        }
        return errors;
    };

    return (
        <section className="book-table-area ptb-100">
            <div className="container">
                <div className="book-table-form">
                    <div className="content">
                        <h3>Book A Table</h3>
                        {bookingSuccess && (
                            <h3>Thank You for booking a table. We will confirm you as soon as possible.</h3>
                        )}
                        {error && (
                            <div className="alert alert-danger" role="alert">
                                {error}
                            </div>
                        )}
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-lg-6 col-sm-6">
                                <div className="form-group">
                                    <input type="text" className="form-control" id="name" placeholder="Enter Your Name" value={formData.name} onChange={handleChange} />
                                    <i className="las la-user"></i>
                                    {formErrors.name && <div className="error-msg">{formErrors.name}</div>}
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-6">
                                <div className="form-group">
                                    <input type="text" className="form-control" id="email" placeholder="Enter Email" value={formData.email} onChange={handleChange} />
                                    <i className="las la-envelope"></i>
                                    {formErrors.email && <div className="error-msg">{formErrors.email}</div>}
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-6">
                                <div className="form-group">
                                    <input type="text" className="form-control" id="phone" placeholder="Enter Phone Number" value={formData.phone} onChange={handleChange} />
                                    <i className="las la-phone"></i>
                                    {formErrors.phone && <div className="error-msg">{formErrors.phone}</div>}
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-6">
                                <div className="form-group">
                                    <select className='form-control' id="time" value={formData.time} onChange={handleChange}>
                                        <option value="0">Choose A Time</option>
                                        <option value="9-9">9:00 am - 9:00 pm</option>
                                        <option value="8-8">8:00 am - 8:00 pm</option>
                                        <option value="7-7">7:00 am - 7:00 pm</option>
                                        <option value="6-6">6:00 am - 6:00 pm</option>
                                        <option value="5-5">5:00 am - 5:00 pm</option>
                                        <option value="4-4">4:00 am - 4:00 pm</option>
                                    </select>	
                                    {formErrors.time && <div className="error-msg">{formErrors.time}</div>}
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-6">
                                <div className="form-group">
                                    <select className='form-control' id="persons" value={formData.persons} onChange={handleChange}>
                                        <option value="">Persons</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="8">8</option>
                                    </select>	
                                    {formErrors.persons && <div className="error-msg">{formErrors.persons}</div>}
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-6">
                                <div className="form-group">
                                    <div className="input-group date" id="datetimepicker">
                                        <DatePicker selected={formData.date} onChange={handleDateChange} className='form-control' />
                                        <span className="input-group-addon"></span>
                                    </div>
                                    <i className="las la-calendar"></i>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="book-table-btn">
                                    <button type="submit" className="default-btn">
                                        Book A Table
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    );
};

export default BookATable;
