import React, { useState } from 'react';
import { Link } from 'react-router-dom';
const Footer = () => {
    const [email, setEmail] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [submitted, setSubmitted] = useState(false);

    const handleChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateEmail(email)) {
            // Handle form submission
            console.log('Email is valid:', email);
            // Clear input field after submission
            setEmail('');
            // Reset validation status
            setIsValidEmail(true);
            // Set submitted status to true
            setSubmitted(true);
        } else {
            // Set validation status to false
            setIsValidEmail(false);
            // Set submitted status to false
            setSubmitted(false);
        }
    };

    const validateEmail = (email) => {
        // Basic email validation regex
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    };

    return (
    <>
<div class="footer-area pt-100 pb-70">
<div class="container">
    <div class="row">
        <div class="col-lg-3 col-sm-6">
            <div class="single-footer-widget">
                <div class="logo">
                    <h2>
                        <a href="index.html">DuckKitchen</a>
                    </h2>
                </div>
                <p>DuckKitchen, a premier restaurant in New Zealand, 
                offers a delectable blend of hot and healthy cuisine, 
                setting the standard for culinary excellence..</p>
                <ul class="social">
                    <li>
                        <a href="#" class="facebook" target="_blank">
                            <i class="lab la-facebook-f"></i>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="twitter" target="_blank">
                            <i class="lab la-twitter"></i>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="pinterest" target="_blank">
                            <i class="lab la-pinterest-p"></i>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="linkedin" target="_blank">
                            <i class="lab la-linkedin-in"></i>
                        </a>
                    </li>
                </ul>
            </div>
        </div>

        <div class="col-lg-3 col-sm-6">
            <div class="single-footer-widget">
                <h3>Newsletter</h3>

                <div class="text">
                    <p>Sign Up our newsletter to get our latest products arrival news first.</p>
                </div>
                {!submitted ? (
                <form className="newsletter-form" onSubmit={handleSubmit}>
                    <input
                        type="email"
                        className={`input-newsletter ${isValidEmail ? '' : 'invalid'}`}
                        placeholder="Email"
                        name="EMAIL"
                        value={email}
                        onChange={handleChange}
                        required
                        autoComplete="off"
                    />
                    <button type="submit">Subscribe</button>
                    {!isValidEmail && (
                        <div id="validator-newsletter" className="form-result">Please enter a valid email address.</div>
                    )}
                </form>
            ) : (
                <div id="success-message" className="form-result"><h3 style={{color:'green'}}>Newsletter Successfully Submitted!</h3></div>
            )}
            </div>
        </div>

        <div class="col-lg-3 col-sm-6">
            <div class="single-footer-widget">
                <h3>Quick Links</h3>

                <ul class="quick-links">
                    <li>
                        <i class="las la-angle-double-right"></i>
                        <Link to="/about">About Company</Link>
                    </li>
                    <li>
                        <i class="las la-angle-double-right"></i>
                        <Link to="/book-a-table">Book A Table</Link>
                    </li>
                    <li>
                        <i class="las la-angle-double-right"></i>
                        <Link to="/shop">Shop</Link>
                    </li>
                    <li>
                        <i class="las la-angle-double-right"></i>
                        <Link to="/faqs">FAQ</Link>
                    </li>
                    <li>
                        <i class="las la-angle-double-right"></i>
                         <Link to="/blog">BLog</Link>
                    </li>
                    <li>
                        <i class="las la-angle-double-right"></i>
                        <Link to="/gallery">Gallery</Link>
                    </li>
                </ul>
            </div>
        </div>

        <div class="col-lg-3 col-sm-6">
            <div class="single-footer-widget">
                <h3>Information</h3>

                <ul class="footer-contact-info">
                    <li>
                        <i class="las la-phone"></i>
                        <span>Phone</span>
                        <a href="tel:+64 21 123 4567">+64 21 123 4567</a>
                    </li>
                    <li>
                        <i class="las la-envelope"></i>
                        <span>Email</span>
                        <a href="email"><span class="__cf_email__" data-cfemail="d8b0bdb4b4b798aeb1a2b7f6bbb7b5">info@duckkitchen.store</span></a>
                    </li>
                    <li>
                        <i class="las la-map-marker-alt"></i>
                        <span>Address</span>
                        <a href="#">
                        New Steer, Main Road
                        Auckland
                        New Zealand
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
</div>

<div class="copyright-area">
<div class="container">
    <div class="copyright-area-content">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <p>
                    Copyright © 2024 Duck Kitchen. All Rights Reserved. 
                </p>
            </div>

            <div class="col-lg-6 col-md-6">
                <ul>
                    <li>
                       <Link to="/terms">Terms & Conditions</Link>
                    </li>
                    <li>
                        <Link to="/privacy">Privacy Policy</Link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
</div>

<div class="go-top">
<i class="las la-long-arrow-alt-up"></i>
</div>
</>
    );
}

export default Footer;