import React from 'react';
const Gallery = () => {
    return(
        <>
           
        <div class="page-title-area">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="page-title-content">
                            <h2>Gallery</h2>
                            <ul>
                                <li>
                                    <a href="index.html">Home</a>
                                </li>
                                <li>Gallery</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     
        <section class="gallery-area pt-100 pb-70">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="single-gallery-item">
                            <img src="assets/img/gallery/gallery1.jpg" alt="image" />

                            <div class="gallery-content">
                                <span>Pizza</span>
                                <h3>Speedy pizza</h3>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <div class="single-gallery-item">
                            <img src="assets/img/gallery/gallery2.jpg" alt="image" />

                            <div class="gallery-content">
                                <span>Pizza</span>
                                <h3>Angry pizza</h3>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <div class="single-gallery-item">
                            <img src="assets/img/gallery/gallery3.jpg" alt="image" />

                            <div class="gallery-content">
                                <span>Pizza</span>
                                <h3>Pizza delicious</h3>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <div class="single-gallery-item">
                            <img src="assets/img/gallery/gallery4.jpg" alt="image" />

                            <div class="gallery-content">
                                <span>Pizza</span>
                                <h3>Prime pizza</h3>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <div class="single-gallery-item">
                            <img src="assets/img/gallery/gallery5.jpg" alt="image" />

                            <div class="gallery-content">
                                <span>Pizza</span>
                                <h3>Buddy’s Pizza</h3>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <div class="single-gallery-item">
                            <img src="assets/img/gallery/gallery6.jpg" alt="image" />

                            <div class="gallery-content">
                                <span>Pizza</span>
                                <h3>Sicilian pizza</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      
        </>
    )
}
export default Gallery;