import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateItemQuantity, removeItem } from '../reducers/cartSlice';
import { Link } from 'react-router-dom';
const Cart = () => {
  
  const dispatch = useDispatch();
  const cartItems = useSelector(state => state.cart.items);

  // Calculate total price
  const totalPrice = cartItems.reduce((total, item) => total + (item.price * item.quantity), 0);

  // Function to handle quantity increment
  const handleIncrement = (itemId) => {
    const item = cartItems.find(item => item._id === itemId);

    console.log('items',itemId);

    if (item) {
      dispatch(updateItemQuantity({ id: itemId, quantity: item.quantity + 1 }));
    }
  };

  // Function to handle quantity decrement
  const handleDecrement = (itemId) => {
    const item = cartItems.find(item => item.id === itemId);
    if (item && item.quantity > 1) {
      dispatch(updateItemQuantity({ id: itemId, quantity: item.quantity - 1 }));
    }
  };

  // Function to handle item removal
  const handleRemoveItem = (itemId) => {
    dispatch(removeItem(itemId));
  };

  return (
    <>
      <div className="page-title-area">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>Cart</h2>
                <ul>
                  <li>
                    <a href="index.html">Home</a>
                  </li>
                  <li>Cart</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="cart-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <form>
                <div className="cart-table table-responsive">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">Product</th>
                        <th scope="col">Name</th>
                        <th scope="col">Unit Price</th>
                        <th scope="col">Quantity</th>
                        <th scope="col">Total</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cartItems.map(item => (
                        <tr key={item.id}>
                          <td className="product-thumbnail">
                            <a href="#">
                            {item.images.map((image, index) => (
                    <img key={index} src={ image} alt={`Product ${index + 1}`} style={{  marginRight: '5px' }} />
                  ))}
                            </a>
                          </td>
                          <td className="product-name">
                            <a href="#">{item.productName}</a>
                          </td>
                          <td className="product-price">
                            <span className="unit-amount">${item.price}</span>
                          </td>
                          <td className="product-quantity">
                            <div className="input-counter">
                              <span className="minus-btn" onClick={() => handleDecrement(item._id)}>
                                <i className="las la-minus"></i>
                              </span>
                              <input type="text" value={item.quantity} readOnly />
                              <span className="plus-btn" onClick={() => handleIncrement(item._id)}>
                                <i className="las la-plus"></i>
                              </span>
                            </div>
                          </td>
                          <td className="product-subtotal">
                            <span className="subtotal-amount">${item.price * item.quantity}</span>
                          </td>
                          <td className="product-actions">
                            <a href="#" className="remove" onClick={() => handleRemoveItem(item._id)}>
                              <i className="las la-trash"></i>
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div class="cart-buttons">
                                <div class="row align-items-center">
                                    <div class="col-lg-7 col-sm-7 col-md-7">
                                        <Link to="/" class="default-btn">
                                            Back to Shop
                                        </Link>
                                    </div>
                                    <div class="col-lg-5 col-sm-5 col-md-5">
                                        <a href="#" class="default-btn">
                                            Update Cart
                                        </a>
                                    </div>
                                </div>
                            </div>
                <div className="cart-totals">
                  <h3>Cart Totals</h3>
                  <ul>
                    <li>Subtotal
                      <span>${totalPrice}</span>
                    </li>
                    {/* Shipping, Total, and Proceed to Checkout */}
                  </ul>
                  <Link to="/checkout" class="default-btn">
                                    Proceed to Checkout
                   </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Cart;
