import React from 'react';
import { useSelector } from 'react-redux'; // Import useSelector hook from react-redux
import { Link } from 'react-router-dom';
const Terms = () => {
return(
<>

        <div class="page-title-area">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="page-title-content">
                            <h2>Terms of Service</h2>
                            <ul>
                                <li>
                                    <a href="index.html">Home</a>
                                </li>
                                <li>Terms of Service</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
   
        <section class="terms-of-service-area ptb-100">
            <div class="container">
				<div class="privacy-content">

               
<p>DuckKItchen,  own and operate www.duckkitchen.store (our “Website”). We supply alcohol under our licence and all orders by you are subject to our licence and these Terms and Conditions.</p>

<p>Our website allows you to make venue bookings, event bookings, transport bookings and also to purchase our wines.  We supply and provide alcohol under our licence and all orders by you are subject to our licence and these Terms and Conditions.</p>

<p>Thank you for reading our Terms and Conditions, which apply to your access and use of our Website and all bookings and orders placed with us (the “Services”). By making a booking or placing an order with us, or otherwise accessing or using the Services, you agree that you have read, understood and accepted these Terms and Conditions.  We may from time to time revise the Terms and Conditions without notice and such revision will take effect when it is posted on the Website.  Your continued use of the Website will be regarded as your acceptance of these Terms and Conditions as amended.</p>

 

<p>1. Updates to our Terms and Conditions
1.1  We may amend our Terms and Conditions from time to time and will notify you of any changes by posting an updated version on our Website. By continuing to use the Services after any changes to our Terms and Conditions, you are deemed to have accepted and shall be bound by those changes. If at any time you no longer agree to our Terms and Conditions, you must immediately discontinue using the Services and tell us.</p>

 

<p>2. Your account and personal information
2.1  To make an alcohol purchase on our Website, you must create an account with us. To do that you must be 18 years of age or older, provide us with your personal information as indicated and agree to comply with these Terms and Conditions.</p>


<p>2.2  You agree that we may use your personal information in accordance with the Privacy Act 2020 and our Privacy Policy (www.ayrburn.com/privacy-policy/), including as reasonably necessary to provide you with the Services and to communicate with you in relation to the Services.</p>

 

<p>3. Purchase of alcohol
3.1  When purchasing products through our Websites, you confirm and warrant that:

(a) you are 18 years of age or older and legally permitted to purchase alcohol in your country of residence (if your purchase includes any products containing alcohol);

(b) you can provide sufficient evidence (e.g. NZ Drivers Licence or Passport) if requested by us prior to or when we deliver your purchase; and

(c) all information supplied by you to us is complete, true and correct.

3.2  We reserve the right to refuse your order or delivery for any reason at our sole discretion. If we exercise this right we will refund you any amounts paid (less reasonable delivery costs incurred by us).</p>

 

<p>4. Orders, price and payment
4.1  When you place an order or a booking with us, you are making an offer to buy goods or services. We will collect your delivery address and other relevant details for your order at checkout. Unless otherwise stated, all prices are inclusive of GST and are in New Zealand Dollars.</p>

<p>4.2  All products are sold subject to their availability.</p>

<p>4.3  We reserve the right to limit the number of items that our customers may purchase in a single transaction.</p>

<p>4.4  When we send you an e-mail to confirm that we have received and accepted your order, this indicates that your order has been accepted and you may not cancel it. We will take payment from you when we accept your order.</p>

<p>4.5  We work hard to despatch orders promptly once you receive confirmation of your order from us via email. We may take longer to process orders during peak trading times, holidays, and sale periods and appreciate your patience during these times. Any stated delivery times on our Website are estimated delivery timeframes only. We will deliver your order through third party delivery agents and we are not responsible for any failure or delay in delivery caused by any event outside of our reasonable control.</p>

<p>4.6  We may make available on our Website third party lay-by payment facility services (such as Afterpay) from time to time. Those services are not a service provided by Ayrburn and are subject to their own terms and conditions between you and the relevant third party provider. We make this payment service available for your convenience only. We are not responsible for those third party payment services and we provide no endorsement as to its suitability, nor any representations or warranties to you in relation to the providers or their payment services.</p>


<p>5. Cancellation
5.1  We may add or remove products and services from our Website, and products and services described on our Website may not be available, from time to time. In the event that any product or service ordered is no longer available we reserve the right to refuse or cancel an order or booking.</p>

<p>5.2  While we try to ensure that the price and description of goods or services is accurate, if there is an error with the listing or the product on our Website or a technical error in the processing of your order, we reserve the right to refuse or cancel the order or booking.

5.3  Our right to cancel orders applies to orders or bookings that have been accepted.</p>

 

<p>6. Use of cookies
6.1  We may use cookies on our Website to help us identify you when you use our Website or to help us improve your experience when using the Services. If you do not accept our cookies, some pages may not operate as intended and you may not be able to access certain information on our Website.</p>


<p>7. Third-party content
7.1  Our Website may contain links to other websites. Those websites and links accessed by you are not under our control. We are not responsible for, make no representations or warranties in relation to, and do not endorse the content of those linked websites and provide them solely for your information and convenience.</p>


<p>8. Your consumer rights
8.1  Consumers may have additional rights under the Consumer Guarantees Act 1993 (“CGA”) and Fair Trading Act 1986 (“FTA”) in relation to the Services and any products purchased from our Website. Your consumer rights are in addition to these Terms and Conditions and nothing in these Terms and Conditions is intended to have the effect of limiting or reducing your consumer rights.</p>

<p>8.2  We are committed to meeting our obligations under the CGA and FTA. You should be aware:

(a) The CGA requires that products sold by us must be safe, of acceptable quality, fit for the purpose they have been purchased for, match the description given, match the sample or demonstration model and be of acceptable condition when received by you.

(b) The CGA guarantees are in addition to any other warranty offer(s) provided by the manufacturer, or insurance provider (if applicable).

(c) The CGA does not apply where the product has been misused, tampered with, broken or improperly operated.</p>


<p>9. Liability
9.1  While we endeavour to supply accurate information on our Website, errors and omissions may occur. Ayrburn provides information on our Website "as is", and to the fullest extent permitted by law, makes no representation as to the accuracy, completeness, or reliability of the information on our Website. We reserve the right to make changes to the content on our Website at any time and without notice.</p>

<p>9.2  To the fullest extent permitted by law, Ayrburn, its directors, shareholders, subsidiaries and their respective shareholders, officers, employees, advertisers, content providers and licensors will not be liable in contract, tort (including negligence) or otherwise to you for any direct, indirect or consequential loss or damage arising out of or in connection with the Services, including (without limitation) loss or damage you might suffer as a result of:

(a) errors, mistakes or inaccuracies on our Website or provision of the Services;

(b) product release delays or unavailability;

(c) any act you take, or fail to take, due to any information contained on or referred to on our Website;

(d) personal injury or property damage of any kind resulting from your access or use of our Website or provision of our Services;

(e) any action or inaction outside our reasonable control (including that of third party providers and delivery agents); and

(f) any interruption from or to our Website or Services.


10. Electronic Communications
10.1  We will add you to our mailing list if you place an order or make a booking with us. By subscribing to emails, societies or clubs, and/or text communications, or otherwise providing your email address and/or mobile number, you consent to receiving marketing or promotional emails and/or texts (as the case may be) from time to time.</p>

<p>10.2  You may unsubscribe from our email communications and/or text communications at any time by clicking the unsubscribe link in any promotional or marketing email or text received or by emailing info@duckkitchen.store</p>

 

<p>11. Intellectual Property</p>
<p>11.1  Our Website and its contents are protected by copyright, trademark and other intellectual property rights owned by us and our licensors. All rights are reserved to us. You may not extract, copy or otherwise reproduce any of our content (in any form) without our prior written consent.</p>

 
<p>12.2 Delivery:

a -  You will receive your wine shipment each quarter: February, May, July, November.

b -We work hard to despatch orders promptly once you receive confirmation of your order from us via email.  We may take longer to process orders during peak trading times, holidays, and sale periods and appreciate your patience during these times.   Any stated delivery times on our Website are estimated delivery timeframes only.  We will deliver your order through third party delivery agents and we are not responsible for any failure or delay in delivery caused by any event outside of our reasonable control. </p>

<p>12.3 Cancellation: 

a- By signing up to the Ayrburn Wine Club, you agree that this is a quarterly subscription which constitutes an order for recurring purchase and delivery of products that continues indefinitely.  You can cancel after your fourth dispatch by email to shop@ayrburn.com.  We require at least 2 weeks' notice prior to a shipment.  If less notice than this is received, you will still be charged for and sent that shipment, and the cancellation will take effect from the following quarter's shipment.  

b- Cancelling your membership before receiving four quarterly shipments constitutes an early cancellation and is subject to a cancellation fee of $200.  

12.4 Refund policy:

a- We will replace goods that arrive broken or damaged.  If you believe the wine to be faulty, please do contact us immediately within a 24hour period from delivery.  We will not refund faulty wines if the claim is not made within 24 hours of receival.  If your order has been packed incorrectly or is not what you ordered, please let us know within 24hours of delivery so we can repack and send you the correct wine. </p> 

 

<p>12. General</p>

<p>12.1  These Terms and Conditions (together with our Privacy Policy) constitute the entire agreement between both of us with respect to your use of the Services and your purchase of products on our Website.</p>

<p>12.2  Our Website is provided for use by New Zealand residents. We make no representations that our Website complies with the laws of any country outside of New Zealand. If you access our Website from outside New Zealand, you do so at your own risk and you are responsible for complying with the laws in the place where you use our Website.</p>

<p>12.3  These Terms and Conditions are governed by, and are to be interpreted in accordance with, the laws of New Zealand, and the parties submit to the non-exclusive jurisdiction of the New Zealand courts.</p>

<p>12.4  Any delay or failure to enforce our rights under these Terms and Conditions does not mean we have waived those rights.</p>

 

<p>13. Governing Law
These Terms and Conditions are governed by, and will be construed in accordance with, New Zealand law. </p> 

 

<p>14. Disclaimers and Limitation
Use of the Website by you is at your sole risk.  Any information provided on the Website has been provided as information about Ayrburn.  While Ayrburn provides the information on the Website in good faith, we are not responsible for any reliance by you on any information contained in or accessed through the Website.  Ayrburn accepts no liability for any cost or damages of any kind resulting from the use of the Website.  To the maximum extent permitted by law, Ayrburn gives no warranties of any kind, whether express, implied, statutory or otherwise, including warranties or representations that the Website or any data, information, advice, opinion, service or product provided, obtained or generated by or through the Website will be complete, accurate, reliable, timely or error-free or that the Website will be secure and provide un-interrupted access, and any such representations or warranties  are expressly disclaimed.</p>

 

<p>Contact us
We are part of the Winton Group. If you have any questions regarding these Terms and Conditions, please contact us at info@duckkitchen.store.</p>     
                </div>
            </div>
        </section>
       

</>
 );
}

export default Terms;