import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProducts } from '../reducers/productSlice';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css'; 
import { fetchBlogs } from '../reducers/blogSlice';
import { Link } from 'react-router-dom';
const Home = () => {
    const dispatch = useDispatch();
  const products = useSelector(state => state.products.products);
  const blogs = useSelector(state => state.blogs.blogs);
  const imagePath = useSelector(state => state.global.imagePath);
  
  const options = {
    items: 4,
    loop: true,
    margin: 10,
    autoplay: true,
    autoplayTimeout: 2000,
    autoplayHoverPause: true,
  };

  useEffect(() => {
    dispatch(fetchProducts());
    dispatch(fetchBlogs());
  }, [dispatch]);


    return (
        <>
<div class="main-banner">
            <div class="main-banner-item">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="main-banner-content">
                                <h1>We serve fresh and Hot Food only</h1>
                                <p>Savor the delectable flavors of our freshly cooked meals, served piping 
                                hot and bursting with freshness. Satisfaction guaranteed!</p>
                                <div class="banner-btn">
                                    <a href="menu.html" class="default-btn">
                                        View Our Menu
                                    </a>
                                    <Link to="/book-a-table" class="default-btn-two">
                                       Book A Table
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        <section class="chef-area ptb-100">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="chef-item">
                            <h3>Taste our wide variety of spicy foods crafted by our Master Chef </h3>
                            <p>Taste our wide variety of gourmet collections from our shop sections. 
                            Discover freshly baked foods that are not only delicious but also healthy. 
                            Indulge in our diverse range of flavors, carefully crafted for your enjoyment.
                             Experience the perfect blend of taste and nutrition in every bite. 
                            Visit us today and treat yourself!.</p>

                            <ul class="chef-list">
                                <li>
                                    <i class="las la-hamburger"></i>
                                    <span>Vegan Burger</span>
                                </li>
                                <li>
                                    <i class="las la-ice-cream"></i>
                                    <span>Drumstick</span>
                                </li>
                                <li>
                                    <i class="las la-drumstick-bite"></i>
                                    <span>Chicken Shawarma</span>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="chef-image">
                            <img src="assets/img/chef.png" alt="image" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
       
        <section class="yummy-area ptb-100">
            <div class="container">
                <div class="yummy-content">
                    <h3>Book A Table</h3>
                   
                    <div class="yummy-btn">
                        <Link to="/book-a-table" class="default-btn">Book A Table</Link>
                    </div>
                </div>
            </div>
        </section>
       
        <section class="pizza-area ptb-100">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="pizza-image">
                            <img src="assets/img/pizza/main-pizza.png" alt="image" />
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="pizza-item">
                            <h3>Pizza</h3>

                            <div class="pizza-list">
                                <div class="image">
                                    <img src="assets/img/pizza/pizza1.png" alt="image" />
                                </div>
                                <h4>
                                    <a href="#">Okaya Smokey Pizza</a>
                                    <span class="price">$35.00</span>
                                </h4>
                                <p>Smoked mozzarella, basil, and pecorino Romano</p>
                            </div>

                            <div class="pizza-list">
                                <div class="image">
                                    <img src="assets/img/pizza/pizza1.png" alt="image" />
                                </div>
                                <h4>
                                    <a href="#">White Ricotta Pizza</a>
                                    <span class="price">$30.00</span>
                                </h4>
                                <p>Smoked mozzarella, basil, and pecorino Romano</p>
                            </div>

                            <div class="pizza-list">
                                <div class="image">
                                    <img src="assets/img/pizza/pizza1.png" alt="image" />
                                </div>
                                <h4>
                                    <a href="#">Calzone</a>
                                    <span class="price">$25.00</span>
                                </h4>
                                <p>Smoked mozzarella, basil, and pecorino Romano</p>
                            </div>

                            <div class="pizza-list">
                                <div class="image">
                                    <img src="assets/img/pizza/pizza1.png" alt="image" />
                                </div>
                                <h4>
                                    <a href="#">Mercy Margarita</a>
                                    <span class="price">$31.00</span>
                                </h4>
                                <p>Smoked mozzarella, basil, and pecorino Romano</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="pizza-shape">
                <div class="shape1">
                    <img src="assets/img/pizza/shape1.png" alt="image" />
                </div>
                <div class="shape2">
                    <img src="assets/img/pizza/shape2.png" alt="image" />
                </div>
                <div class="shape3">
                    <img src="assets/img/pizza/shape3.png" alt="image" />
                </div>
                <div class="shape4">
                    <img src="assets/img/pizza/shape4.png" alt="image" />
                </div>
                <div class="shape5">
                    <img src="assets/img/pizza/shape5.png" alt="image" />
                </div>
            </div>
        </section>
       
        <section class="choose-area pb-70">
            <div class="container">
                <div class="section-title">
                    <h2>Why People Choose Us</h2>
                    <p>DuckKitchen, a New Zealand-based restaurant, offers delicious
                    and healthy food at affordable prices. Our menu features a diverse range 
                    of flavorful dishes crafted with quality ingredients. Experience culinary
                     excellence without breaking the bank. Visit us today for
                     a satisfying dining experience that nourishes both body and soul!</p>
                     <br/>
                     <br/>
                     <div class="yummy-btn">
                        <Link to="/about" class="default-btn">Learn More</Link>
                    </div>
                </div>

                <div class="choose-slider owl-carousel owl-theme">
                    <div class="choose-item">
                        <div class="icon">
                            <i class="las la-tag"></i>
                        </div>
                        <h3>Discount System</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod incididunt labore dolore magna aliqua.</p>
                    </div>

                    <div class="choose-item">
                        <div class="icon">
                            <i class="las la-truck"></i>
                        </div>
                        <h3>Express Delivery</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod incididunt labore dolore magna aliqua.</p>
                    </div>

                    <div class="choose-item">
                        <div class="icon">
                            <i class="las la-utensils"></i>
                        </div>
                        <h3>50+ Restaurant</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod incididunt labore dolore magna aliqua.</p>
                    </div>

                    <div class="choose-item">
                        <div class="icon">
                            <i class="las la-tag"></i>
                        </div>
                        <h3>Discount System</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod incididunt labore dolore magna aliqua.</p>
                    </div>

                    <div class="choose-item">
                        <div class="icon">
                            <i class="las la-truck"></i>
                        </div>
                        <h3>Express Delivery</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod incididunt labore dolore magna aliqua.</p>
                    </div>

                    <div class="choose-item">
                        <div class="icon">
                            <i class="las la-utensils"></i>
                        </div>
                        <h3>50+ Restaurant</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod incididunt labore dolore magna aliqua.</p>
                    </div>
                </div>
            </div>
        </section>
     
        <section class="order-area">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="order-content">
                            <h3>Make Your First Order And Get <span>20% Off</span></h3>

                            <div class="order-btn">
                                <Link to="/shop" class="default-btn">Order Now</Link>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="order-image">
                            <img src="assets/img/order/order1.png" alt="image" />

                            <div class="order-text">
                                <h4>25%</h4>
                                <span>Off</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      
        <section class="dishes-area pt-100 pb-70">
            <div class="container">
                <div class="section-title">
                    <h2>Popular Dishes</h2>
                    <p>
                    Discover our top dishes! 
                    Place your order now to indulge in our most popular food collection.
                    </p>
                </div>

                <div class="row product_lists">
                

                {products.map((product, index) => (
              <div className="col-lg-4 col-md-6" key={index}>
                <div className="dishes-item">
                  <div className="image">
                  <Link to={`/product/${product.id}`}>
                     {/* Render multiple image elements */}
                  {product.product_images.map((image, index) => (
                    <img key={index} src={imagePath + image} alt={`Product ${index + 1}`} style={{  marginRight: '5px' }} />
                  ))}
                    
                    </Link>
                  </div>

                  <div className="content">
                    <h3>
                    <Link to={`/product/${product._id}`}>
                         {product.productName}
                    </Link> {/* Assuming each product object has a 'productName' property */}
                    </h3>
                    <span>{product.description}</span> {/* Assuming each product object has a 'category' property */}

                    <div className="dishes-btn">
                    <Link to={`/product/${product._id}`} className="default-btn">Add To Cart</Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
                   

                </div>
            </div>
        </section>
      
        <section class="testimonial-area ptb-100">
            <div class="container">
                <div class="section-title">
                    <h2>Testimonials</h2>
                    <p>
                    DuckKitchen has completely exceeded my expectations. 
                    Every dish I've tried has been a flavor explosion! 
                    From their succulent burgers to their fresh salads, each bite is a delight.
                    The quality of ingredients is evident, and the attention to detail 
                    in their preparation is remarkable. DuckKitchen has become my favorite spot 
                    for a satisfying and delicious meal. I highly recommend it to anyone
                     looking for a culinary experience that is both indulgent and wholesome.
                     Thank you, DuckKitchen, for consistently delivering excellence
                    </p>
                </div>

                <div class="testimonial-slider owl-carousel owl-theme">
                    <div class="testimonial-item">
                        <div class="info">
                            <img src="assets/img/client/client1.jpg" alt="image" />
                            <h3>Ken Morris</h3>
                            <span>Artist</span>
                        </div>
                        <div class="text">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum</p>
                        </div>

                        <div class="icon">
                            <i class="flaticon-right-quotes-symbol"></i>
                        </div>
                    </div>

                    <div class="testimonial-item">
                        <div class="info">
                            <img src="assets/img/client/client2.jpg" alt="image" />
                            <h3>Johansen Lisa</h3>
                            <span>Artist</span>
                        </div>
                        <div class="text">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum</p>
                        </div>

                        <div class="icon">
                            <i class="flaticon-right-quotes-symbol"></i>
                        </div>
                    </div>

                    <div class="testimonial-item">
                        <div class="info">
                            <img src="assets/img/client/client3.jpg" alt="image" />
                            <h3>Lodi Kheda</h3>
                            <span>Artist</span>
                        </div>
                        <div class="text">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum</p>
                        </div>

                        <div class="icon">
                            <i class="flaticon-right-quotes-symbol"></i>
                        </div>
                    </div>
                </div>
            </div>
        </section>


    <section style={{marginTop:'100px',marginBottom:"30px"}}>

    <div class="section-title">
                    <h2>Our Latest News</h2>
                    <p>Keep updated yourself with our latest news.</p>
   </div>

    <div class="blog-slider owl-carouses owl-themes container"> 
    <OwlCarousel
      className="owl-theme"
      {...options}
    >
   {blogs.map((blog) => (
  <div className="blog-item item" key={blog._id}>
    <div className="image">
    <Link to={`/blog-details/${blog._id}`}> 
        {/* <img src={blog.image} alt="image" /> */}
        <img src="assets/img/blog/blog1.jpg" alt="image" />
      </Link>
      <div className="date">
        <span>{new Date(blog.date).toLocaleDateString()}</span>
      </div>
    </div>
    <div className="content">
      <h3>
      <Link to={`/blog-details/${blog._id}`}> {blog.title}</Link>
      </h3>
      <Link to={`/blog-details/${blog._id}`} className="blog-btn">Read More +</Link>
    </div>
  </div>
))}

    </OwlCarousel>
    </div>
    </section>

</>

);
}

export default Home;