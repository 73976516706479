import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Define the initial state
const initialState = {
  status: 'idle',
  error: null,
};
const API = 'https://react-ecom.acesoftech.co.in';
// Create an async thunk to send email data to the server
export const sendEmail = createAsyncThunk(
  'email/sendEmail',
  async (emailData, { rejectWithValue }) => {
    try {
      const response = await axios.post(API+'/api/email/sendEmail', emailData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Create a slice for email
const emailSlice = createSlice({
  name: 'email',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(sendEmail.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(sendEmail.fulfilled, (state) => {
        state.status = 'succeeded';
        state.error = null;
      })
      .addCase(sendEmail.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload ? action.payload.message : 'An error occurred';
      });
  },
});

// Export the reducer
export default emailSlice.reducer;
