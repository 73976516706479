import React,{useState} from 'react';
import { useSelector } from 'react-redux'; // Import useSelector hook from react-redux
import { Link } from 'react-router-dom';
const Header = () => {
    const cartItems = useSelector(state => state.cart.items); // Select items from the cart state

    // Calculate total count of items in the cart
    const totalCount = cartItems.reduce((total, item) => total + item.quantity, 0);

    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

    const toggleSubMenu = () => {
      setIsSubMenuOpen(!isSubMenuOpen);
    };

    return (
<div class="navbar-area">
<div class="main-responsive-nav">
    <div class="container">
        <div class="main-responsive-menu">
            <div class="logo">
                <Link to="/"> 
                    <img src="https://duckkitchen.store/assets/img/logo.png" alt="image" />
                </Link>
            </div>
        </div>
    </div>
</div>

<div class="main-navbar">
    <div class="container">
        <nav class="navbar navbar-expand-md navbar-light">
            <Link class="navbar-brand" to="/">
                <img src="https://duckkitchen.store/assets/img/logo.png" alt="image" />
            </Link>

            <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <Link to="/" class="nav-link active">
                            Home 
                           
                        </Link>
                    
                    </li>

                    <li class="nav-item">
                        <Link to="/about" class="nav-link">
                            About
                        </Link>
                    </li>

                 

                    <li class="nav-item">
                        <Link to="/book-a-table" class="nav-link">
                          Book a Table 
                        </Link>
                    </li>
{/* 
                    <li class="nav-item">
                        <a href="#" class="nav-link">
                            Blog 
                            <i class="las la-angle-down"></i>
                        </a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <a href="blog.html" class="nav-link">
                                    Blog
                                </a>
                            </li>

                            <li class="nav-item">
                                <a href="blog-details.html" class="nav-link">
                                    Blog Details
                                </a>
                            </li>
                        </ul>
                    </li> */}

                    <li class="nav-item">
                        <Link to="/shop" class="nav-link">
                            Shop
                        </Link>
                    </li>
                    <li class="nav-item">
                        <Link to="/contact" class="nav-link">
                            Contact
                        </Link>
                    </li>

                    <li class="nav-item">
                    <a href="#" class="nav-link">
                        My Account
                    </a>
                    <ul class="dropdown-menu">
                                   
                                   <li class="nav-item">
                                       <Link to="/register" class="nav-link">
                                           Register
                                       </Link>
                                  </li>
                                  <li class="nav-item">
                                       <Link to="/login" class="nav-link">
                                           Login
                                       </Link>
                                  </li>
                     </ul>              
                     </li>
                     
                   
                    <li class="nav-item">
                                <a href="#" class="nav-link">
                                    More 
                                    <i class="las la-angle-down"></i>
                                </a>

                                <ul class="dropdown-menu">
                                   
                                    <li class="nav-item">
                                        <Link to="/faqs" class="nav-link">
                                            FAQs
                                        </Link>
                                    </li>

                                    <li class="nav-item">
                                        <Link to="/gallery" class="nav-link">
                                            Gallery
                                        </Link>
                                    </li>

                                    <li class="nav-item">
                                        <Link to="/chefs" class="nav-link">
                                            Chefs
                                        </Link>
                                    </li>
                                  
                                </ul>
                            </li>
                </ul>

                <div class="others-options d-flex align-items-center">
                    <div class="option-item">
                        <div class="cart-btn">
                        <Link to="/cart">
                                <i className="las la-shopping-cart"></i>
                                <span>{totalCount}</span> {/* Display total count */}
                            </Link>
                        </div>
                    </div>

                    <div class="option-item">
                        <i class="search-btn flaticon-loupe"></i>
                        <i class="close-btn flaticon-cancel"></i>
                        <div class="search-overlay search-popup">
                            <div class='search-box'>
                                <form class="search-form">
                                    <input class="search-input" name="search" placeholder="Search" type="text" />

                                    <button class="search-button" type="submit">
                                        <i class="flaticon-loupe"></i>
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </div>
</div>

<div class="others-option-for-responsive">
    <div class="container">
        <div class="dot-menu">
            {/* <div class="inner">
                <div class="circle circle-one"></div>
                <div class="circle circle-two"></div>
                <div class="circle circle-three"></div>
            </div> */}
            <style>
        {`
          /* CSS styles */
          .duck_menu {
            position: relative;
          }

          .duck_submenu {
            display: none;
            position: absolute;
            top: 100%;
            left: -104px;
            width: 200px;
            background-color: #fff;
            border: 1px solid #ccc;
            padding: 10px;
            }

          .duck_submenu.open {
            display: block;
          }
          .duck_submenu.open ul {
            list-style:none;
            padding-left: 1rem;
          }

          .duck_submenu.open ul li{
            border-bottom:dotted 1px black
            }

          .duck_hamburger {
            cursor: pointer;
          }
        `}
      </style>
            <div className="duck_menu">
      <div className="duck_hamburger" onClick={toggleSubMenu}>
        &#9776; {/* Hamburger icon */}
      </div>
      {/* Submenu */}
      <div className={isSubMenuOpen ? "duck_submenu open" : "duck_submenu"}>
        <ul>
          <li><Link to='/about'>About</Link></li>
          <li><Link to='/book-a-table'>Book A Table</Link></li>
          <li><Link to='/shop'>Shop</Link></li>
          <li><Link to='/contact'>Contact</Link></li>
          <li>My Account
           <ul>
                <li><Link to='/register'>Register</Link></li>
                <li><Link to='/login'>Login</Link></li>
            </ul>
          </li>

          <li>Other
           <ul>
                <li><Link to='/faqs'>FAQs</Link></li>
                <li><Link to='/chefs'>Chefs</Link></li>
                <li><Link to='/gallery'>Gallery</Link></li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
        </div>
        
        <div class="container">
            <div class="option-inner">
                <div class="others-options d-flex align-items-center">
                    <div class="option-item">
                        <div class="cart-btn">
                            <a href="cart.html">
                                <i class="las la-shopping-cart"></i>
                                <span>0</span>
                            </a>
                        </div>
                    </div>

                    <div class="option-item">
                        <i class="search-btn flaticon-loupe"></i>
                        <i class="close-btn flaticon-cancel"></i>
                        <div class="search-overlay search-popup">
                            <div class='search-box'>
                                <form class="search-form">
                                    <input class="search-input" name="search" placeholder="Search" type="text" />

                                    <button class="search-button" type="submit">
                                        <i class="flaticon-loupe"></i>
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
 );
}

export default Header;