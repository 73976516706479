// // blogSlice.js
// import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import axios from 'axios';

// // Define initial state
// const initialState = {
//   blogs: [],
//   loading: false,
//   error: null
// };
// const API='http://localhost:5000/blogs'
// // Define thunk for fetching blogs
// export const fetchBlogs = createAsyncThunk(
//   'blogs/fetchBlogs',
//   async () => {
//     const response = await axios.get('/api/blogs');

//     console.log('KKKK',response);

//     return response.data;
//   }
// );

// // Create a slice
// const blogSlice = createSlice({
//   name: 'blogs',
//   initialState,
//   reducers: {},
//   extraReducers: {
//     [fetchBlogs.pending]: (state) => {
//       state.loading = true;
//       state.error = null;
//       console.log('AAPENDINGASSSS', state.error);
//     },
//     [fetchBlogs.fulfilled]: (state, action) => {
//       state.loading = false;
//       state.blogs = action.payload;

//       console.log('FULFILLED',state.blogs);
//     },
//     [fetchBlogs.rejected]: (state, action) => {
//       state.loading = false;
//       state.error = action.error.message;

//       console.log('REJECT', state.error);
//     }
//   }
// });

// // Export actions and reducer
// export const blogActions = blogSlice.actions;
// export default blogSlice.reducer;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const API = 'https://react-ecom.acesoftech.co.in';

// Async thunk to fetch all blogs
export const fetchBlogs = createAsyncThunk(
  'blogs/fetchBlogs',
  async () => {
    const response = await axios.get(`${API}/api/blogs`);
    return response.data;
  }
);

// Async thunk to fetch a single blog
export const fetchSingleBlog = createAsyncThunk(
  'blogs/fetchSingleBlog',
  async (blogId) => {
    const response = await axios.get(`${API}/api/blogs/${blogId}`);

    // console.log('AAAAA',response.data)
    return response.data;
  }
);

const blogSlice = createSlice({
  name: 'blogs',
  initialState: {
    blogs: [],
    blog: null, // Add a new property to store the single blog
    loading: false,
    error: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchBlogs.pending, state => {
        state.loading = true;
      })
      .addCase(fetchBlogs.fulfilled, (state, action) => {
        state.loading = false;
        state.blogs = action.payload;
        state.error = null;
      })
      .addCase(fetchBlogs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchSingleBlog.pending, state => {
        state.loading = true;
      })
      .addCase(fetchSingleBlog.fulfilled, (state, action) => {
        state.loading = false;
        state.blog = action.payload;
     
        // console.log('FullFilled',state.blog);

        state.error = null;
      })
      .addCase(fetchSingleBlog.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

export default blogSlice.reducer;
