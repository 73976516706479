import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchSingleBlog } from '../../reducers/blogSlice';

const BlogDetails = () => {
  const { blogId } = useParams();
  const dispatch = useDispatch();
  const { blog, loading, error } = useSelector((state) => state.blogs);
  console.log('blogId',blog);

  useEffect(() => {

   
    dispatch(fetchSingleBlog(blogId));
  }, [dispatch, blogId]);

  return (
    
  <>

{loading ? (
  <p>Loading...</p>
) : (
  blog ? (
    <div>
<div class="page-title-area item-bg1">
  <div class="d-table">
      <div class="d-table-cell">
          <div class="container">
              <div class="page-title-content">
                  <h2>{blog.title}</h2>
                  <ul>
                      <li>
                          <a href="index.html">Home</a>
                      </li>
                      <li>Blog Details</li>
                  </ul>
              </div>
          </div>
      </div>
  </div>
</div>

<section class="blog-details-area pt-100 pb-100">
  <div class="container">
      <div class="row">
          <div class="col-lg-8 col-md-12">
              <div class="blog-details-desc">
                  <div class="article-image">
                      <img src="assets/img/blog-details.jpg" alt="image" />
                  </div>
                  <div class="article-content">
                      <div class="entry-meta">
                          <ul>
                              <li>
                                  <span>Posted On:</span> 
                                  <span>{new Date(blog.date).toLocaleDateString()}</span>
                              </li>
                              <li>
                                  <span>Posted By:</span> 
                                  <a href="#">John Anderson</a>
                              </li>
                          </ul>
                      </div>

                      <p>
                      {blog.content}
                      </p>
                 
                    </div>

                  <div class="article-footer">
                   

                      <div class="article-share">
                          <ul class="social">
                              <li><span>Share:</span></li>
                              <li>
                                  <a href="#" target="_blank">
                                      <i class="lab la-facebook-f"></i>
                                  </a>
                              </li>
                              <li>
                                  <a href="#" target="_blank">
                                      <i class="lab la-twitter"></i>
                                  </a>
                              </li>
                              <li>
                                  <a href="#" target="_blank">
                                      <i class="lab la-instagram"></i>
                                  </a>
                              </li>
                          </ul>
                      </div>
                  </div>

               
                  <div class="comments-area">
                      <h3 class="comments-title">No Comments:</h3>
                      {/* <ol class="comment-list">
                          <li class="comment">
                              <article class="comment-body">
                                  <footer class="comment-meta">
                                      <div class="comment-author vcard">
                                          <img src="assets/img/client/client1.jpg" class="avatar" alt="image" />
                                          <b class="fn">John Jones</b>
                                          <span class="says">says:</span>
                                      </div>
                                      <div class="comment-metadata">
                                          <a href="index.html">
                                              <time>April 24, 2021 at 10:59 am</time>
                                          </a>
                                      </div>
                                  </footer>
                                  <div class="comment-content">
                                      <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen.</p>
                                  </div>
                                  <div class="reply">
                                      <a href="#" class="comment-reply-link">Reply</a>
                                  </div>
                              </article>

                              <ol class="children">
                                  <li class="comment">
                                      <article class="comment-body">
                                          <footer class="comment-meta">
                                              <div class="comment-author vcard">
                                                  <img src="assets/img/client/client2.jpg" class="avatar" alt="image" />
                                                  <b class="fn">Steven Smith</b>
                                                  <span class="says">says:</span>
                                              </div>
                                              <div class="comment-metadata">
                                                  <a href="index.html">
                                                      <time>April 24, 2021 at 10:59 am</time>
                                                  </a>
                                              </div>
                                          </footer>
                                          <div class="comment-content">
                                              <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen.</p>
                                          </div>
                                          <div class="reply">
                                              <a href="#" class="comment-reply-link">Reply</a>
                                          </div>
                                      </article>
                                  </li>
                              </ol>

                              <article class="comment-body">
                                  <footer class="comment-meta">
                                      <div class="comment-author vcard">
                                          <img src="assets/img/client/client3.jpg" class="avatar" alt="image" />
                                          <b class="fn">Sarah Taylor</b>
                                          <span class="says">says:</span>
                                      </div>
                                      <div class="comment-metadata">
                                          <a href="index.html">
                                              <time>April 24, 2021 at 10:59 am</time>
                                          </a>
                                      </div>
                                  </footer>
                                  <div class="comment-content">
                                      <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen.</p>
                                  </div>
                                  <div class="reply">
                                      <a href="#" class="comment-reply-link">Reply</a>
                                  </div>
                              </article>
                          </li>
                      </ol> */}
                  </div>

                  <div class="comment-respond-reply">
                      <h3 class="comment-reply-title">Leave a Reply</h3>
                      <form class="comment-form">
                          <p class="comment-notes">
                              <span id="email-notes">Your email address will not be published.</span>
                              Required fields are marked 
                              <span class="required">*</span>
                          </p>
                          <p class="comment-form-comment">
                              <label>Comment</label>
                              <textarea name="comment" id="comment" cols="45" rows="5" maxlength="65525" required="required"></textarea>
                          </p>
                          <p class="comment-form-author">
                              <label>Name <span class="required">*</span></label>
                              <input type="text" id="author" name="author" required="required" />
                          </p>
                          <p class="comment-form-email">
                              <label>Email <span class="required">*</span></label>
                              <input type="email" id="email" name="email" required="required" />
                          </p>
                          <p class="comment-form-url">
                              <label>Website</label>
                              <input type="url" id="url" name="url" />
                          </p>
                          <p class="comment-form-cookies-consent">
                              <input type="checkbox" value="yes" name="wp-comment-cookies-consent" id="wp-comment-cookies-consent" />
                              <label for="wp-comment-cookies-consent">Save my name, email, and website in this browser for the next time I comment.</label>
                          </p>
                          <p class="form-submit">
                              <input type="submit" name="submit" id="submit" class="submit" value="Post A Comment" />
                          </p>
                      </form>
                  </div>
              </div>
          </div>
          
          <div class="col-lg-4 col-md-12">
              <aside class="widget-area" id="secondary">
                  <section class="widget widget_search">
                      <form class="search-form search-top">
                          <label>
                              <span class="screen-reader-text">Search for:</span>
                              <input type="search" class="search-field" placeholder="Search..." />
                          </label>
                          <button type="submit">
                              <i class="flaticon-loupe"></i>
                          </button>
                      </form>
                  </section>
                  
                  <section class="widget widget_vizo_posts_thumb">
                      <h3 class="widget-title">Popular Posts</h3>
                      <article class="item">
                          <a href="#" class="thumb">
                              <span class="fullimage cover bg1" role="img"></span>
                          </a>
                          <div class="info">
                              <time class="2021-06-30">June 10, 2021</time>
                              <h4 class="title usmall">
                                  <a href="#">Restaurant In This City Right Now So We Scour The City</a>
                              </h4>
                          </div>
                      </article>

                      <article class="item">
                          <a href="#" class="thumb">
                              <span class="fullimage cover bg2" role="img"></span>
                          </a>
                          <div class="info">
                              <time class="2021-06-30">June 21, 2021</time>
                              <h4 class="title usmall">
                                  <a href="#">Better Hot Drink Elegant You Can Order By Online</a>
                              </h4>
                          </div>
                      </article>

                      <article class="item">
                          <a href="#" class="thumb">
                              <span class="fullimage cover bg3" role="img"></span>
                          </a>
                          <div class="info">
                              <time class="2021-06-30">June 30, 2021</time>
                              <h4 class="title usmall">
                                  <a href="#">Role Of Genetics In Treating Low-Grade Glioma</a>
                              </h4>
                          </div>
                      </article>
                  </section>

                  <section class="widget widget_categories">
                      <h3 class="widget-title">Categories</h3>
                      <ul>
                          <li>
                              <a href="#">The Pizza Box</a>
                          </li>
                          <li>
                              <a href="#">Oven-Baked Pizzas and Calzones</a>
                          </li>
                          <li>
                              <a href="#">Sugar and Slice Pizza Shop</a>
                          </li>
                          <li>
                              <a href="#">Sterling Pan Pizza Co.</a>
                          </li>
                          <li>
                              <a href="#">Stakeout Pizza</a>
                          </li>
                          <li>
                              <a href="#">Rosa's Italian Pizzeria</a>
                          </li>
                          <li>
                              <a href="#">Hot Spot Pizza Shop</a>
                          </li>
                          <li>
                              <a href="#">Frankie's Fried Pizza</a>
                          </li>
                      </ul>
                  </section>

                  <section class="widget widget_archive">
                      <h3 class="widget-title">Archives</h3>
                      <ul>
                          <li>
                              <a href="#">May 2021</a>
                          </li>
                          <li>
                              <a href="#">April 2021</a>
                          </li>
                          <li>
                              <a href="#">June 2021</a>
                          </li>
                      </ul>
                  </section>

                  <section class="widget widget_tag_cloud">
                      <h3 class="widget-title">Tags</h3>
                      <div class="tagcloud section-bottom">
                          <a href="#">Tomato Poha</a>
                          <a href="#">Salad</a>
                          <a href="#">Mineral Water</a>
                          <a href="#">Kebab</a>
                          <a href="#">Fish Curry</a>
                          <a href="#">Chicken Curry</a>
                          <a href="#">Rice</a>
                          <a href="#">Curd Upma</a>
                          <a href="#">Chana Kulcha</a>
                          <a href="#">Breakfast</a>
                          <a href="#">Lunch</a>
                          <a href="#">Dinner</a>
                          <a href="#">Coffee</a>
                          <a href="#">Snacks</a>
                          <a href="#">Menu</a>
                      </div>
                  </section>
              </aside>
          </div>
      </div>
  </div>
</section>
</div>
) : (
    <p>No blog data available.</p>
  )
)}
</>
  );
};

export default BlogDetails;