import React from 'react';

const About = () => {
    return (
    <>

<section class="about-area ptb-100">
<div class="container">
<div class="row align-items-center">
    <div class="col-lg-6">
        <div class="about-content">
            <h3>A trusted family restaurant brand</h3> 
            <p>We are a trusted family restaurant brand in New Zealand, renowned for our high-quality cuisine. Our commitment to excellence ensures memorable dining experiences for our patrons, setting us apart in the culinary landscape.</p>

            <div class="signature">
                <h4>Amelia Smith</h4>
                <span>Head Of Chef</span>
                <img src="assets/img/signature.png" alt="image" />
            </div>
        </div>
    </div>

    <div class="col-lg-6">
        <div class="about-image">
            <img src="assets/img/about-2.jpg" alt="image" />
        </div>
    </div>
</div>
</div>
</section>



<section class="overview-area pt-100 pb-70">
<div class="container">
<div class="section-title">
    <h2>Our healthy meal services</h2>
    <p>
Explore our extensive selection of nutritious and delectable meals, 
crafted to suit every palate. With affordability in mind, we offer a
 diverse range of options that cater to your taste buds without breaking the bank</p>
</div>

<div class="row">
    <div class="col-lg-4 col-md-6">
        <div class="overview-item">
            <img src="assets/img/overview/overview1.png" alt="image" />

            <div class="content">
                <h3>Breakfast</h3>
                <p>Discover our tantalizing breakfast options, meticulously
                 curated to blend scrumptious flavors with wholesome nutrition. 
                Start your day right with our delectable and nourishing offerings.</p>
            </div>
        </div>
    </div>

    <div class="col-lg-4 col-md-6">
        <div class="overview-item">
            <img src="assets/img/overview/overview2.png" alt="image" />

            <div class="content">
                <h3>Lunch</h3>
                <p>
Indulge in our enticing lunch menu, where flavorsome dishes 
meet nutritional excellence. Savor the perfect balance of taste and
 health with our diverse range of lunchtime delights.</p>
            </div>
        </div>
    </div>

    <div class="col-lg-4 col-md-6">
        <div class="overview-item">
            <img src="assets/img/overview/overview3.png" alt="image" />

            <div class="content">
                <h3>Dinner</h3>
                <p>Experience culinary delight with our dinner offerings.
                 From savory classics to innovative creations, our menu promises
                 to elevate your evening with flavors that satisfy and nourish.</p>
            </div>
        </div>
    </div>
</div>
</div>
</section>



<section class="yummy-area ptb-100">
<div class="container">
<div class="yummy-content">
    <h3>Dont miss the chance</h3>
   
    <h3>Book A Table</h3>
   

    <div class="yummy-btn">
        <a href="cart.html" class="default-btn">Book A Table</a>
    </div>
</div>
</div>
</section>

<section class="team-area bg-ffffff pt-100 pb-70">
<div class="container">
<div class="section-title">
    <h2>Our Chefs</h2>
    <p>
Introducing our skilled Chefs, creators of delectable 
dishes that tantalize the taste buds..</p>
</div>

<div class="row">
    <div class="col-lg-4 col-md-6">
        <div class="single-team">
            <div class="image">
                <img src="assets/img/team/team5.jpg" alt="image" />
            
                <ul class="social">
                    <li>
                        <a href="#" target="_blank">
                            <i class="lab la-facebook-f"></i>
                        </a>
                    </li>
                    <li>
                        <a href="#" target="_blank">
                            <i class="lab la-twitter"></i>
                        </a>
                    </li>
                    <li>
                        <a href="#" target="_blank">
                            <i class="lab la-instagram"></i>
                        </a>
                    </li>
                    <li>
                        <a href="#" target="_blank">
                            <i class="lab la-linkedin-in"></i>
                        </a>
                    </li>
                </ul>

                <div class="content">
                    <h3>Olivia Jade Brown</h3>
                    <span>Founder & CEO</span>
                </div>
            </div>
        </div>
    </div>

    <div class="col-lg-4 col-md-6">
        <div class="single-team">
            <div class="image">
                <img src="assets/img/team/team6.jpg" alt="image" />
            
                <ul class="social">
                    <li>
                        <a href="#" target="_blank">
                            <i class="lab la-facebook-f"></i>
                        </a>
                    </li>
                    <li>
                        <a href="#" target="_blank">
                            <i class="lab la-twitter"></i>
                        </a>
                    </li>
                    <li>
                        <a href="#" target="_blank">
                            <i class="lab la-instagram"></i>
                        </a>
                    </li>
                    <li>
                        <a href="#" target="_blank">
                            <i class="lab la-linkedin-in"></i>
                        </a>
                    </li>
                </ul>

                <div class="content">
                    <h3>Kenneth Linke</h3>
                    <span>Head Of Chef</span>
                </div>
            </div>
        </div>
    </div>

    <div class="col-lg-4 col-md-6">
        <div class="single-team">
            <div class="image">
                <img src="assets/img/team/team7.jpg" alt="image" />
            
                <ul class="social">
                    <li>
                        <a href="#" target="_blank">
                            <i class="lab la-facebook-f"></i>
                        </a>
                    </li>
                    <li>
                        <a href="#" target="_blank">
                            <i class="lab la-twitter"></i>
                        </a>
                    </li>
                    <li>
                        <a href="#" target="_blank">
                            <i class="lab la-instagram"></i>
                        </a>
                    </li>
                    <li>
                        <a href="#" target="_blank">
                            <i class="lab la-linkedin-in"></i>
                        </a>
                    </li>
                </ul>

                <div class="content">
                    <h3>Ella Grace Martin</h3>
                    <span>Assistant Chef</span>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
</section>


<section class="testimonial-area ptb-100">
<div class="container">
<div class="section-title">
    <h2>Testimonials</h2>
    <p>"This family restaurant offers a complete experience! We had a
     lovely time with our loved ones, enjoying every dish served
     --<b>Lily Sophia Anderson</b>
     </p>
</div>

<div class="testimonial-slider owl-carousel owl-theme">
    <div class="testimonial-item">
        <div class="info">
            <img src="assets/img/client/client1.jpg" alt="image" />
            <h3>Ken Morris</h3>
            <span>Artist</span>
        </div>
        <div class="text">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum</p>
        </div>

        <div class="icon">
            <i class="flaticon-right-quotes-symbol"></i>
        </div>
    </div>

    <div class="testimonial-item">
        <div class="info">
            <img src="assets/img/client/client2.jpg" alt="image" />
            <h3>Johansen Lisa</h3>
            <span>Artist</span>
        </div>
        <div class="text">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum</p>
        </div>

        <div class="icon">
            <i class="flaticon-right-quotes-symbol"></i>
        </div>
    </div>

    <div class="testimonial-item">
        <div class="info">
            <img src="assets/img/client/client3.jpg" alt="image" />
            <h3>Lodi Kheda</h3>
            <span>Artist</span>
        </div>
        <div class="text">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum</p>
        </div>

        <div class="icon">
            <i class="flaticon-right-quotes-symbol"></i>
        </div>
    </div>
</div>
</div>
</section>



<section class="dishes-area pt-100 pb-70">
<div class="container">
<div class="section-title">
    <h2>Popular Dishes</h2>
    <p>Discover a wide array of delicious and healthy foods in our menu.
     From savory to sweet,
     we cater to every palate with quality ingredients.</p>
</div>

<div class="row">
    <div class="col-lg-4 col-md-6">
        <div class="dishes-item">
            <div class="image">
                <a href="#">
                    <img src="assets/img/dishes/dishes1.jpg" alt="image" />
                </a>
            </div>
        
            <div class="content">
                <h3>
                    <a href="#">Top Siren Snacks</a>
                </h3>
                <span>Breakfast</span>

                <div class="dishes-btn">
                    <a href="cart.html" class="default-btn">Add To Cart</a>
                </div>
            </div>
        </div>
    </div>

    <div class="col-lg-4 col-md-6">
        <div class="dishes-item">
            <div class="image">
                <a href="#">
                    <img src="assets/img/dishes/dishes2.jpg" alt="image" />
                </a>
            </div>
        
            <div class="content">
                <h3>
                    <a href="#">Strap Snacks</a>
                </h3>
                <span>Lunch</span>
                
                <div class="dishes-btn">
                    <a href="cart.html" class="default-btn">Add To Cart</a>
                </div>
            </div>
        </div>
    </div>

    <div class="col-lg-4 col-md-6">
        <div class="dishes-item">
            <div class="image">
                <a href="#">
                    <img src="assets/img/dishes/dishes3.jpg" alt="image" />
                </a>
            </div>
        
            <div class="content">
                <h3>
                    <a href="#">Mexican Mix Rice</a>
                </h3>
                <span>Dinner</span>
                
                <div class="dishes-btn">
                    <a href="cart.html" class="default-btn">Add To Cart</a>
                </div>
            </div>
        </div>
    </div>

    <div class="col-lg-4 col-md-6">
        <div class="dishes-item">
            <div class="image">
                <a href="#">
                    <img src="assets/img/dishes/dishes4.jpg" alt="image" />
                </a>
            </div>
        
            <div class="content">
                <h3>
                    <a href="#">Cheese & Chicken</a>
                </h3>
                <span>Breakfast</span>

                <div class="dishes-btn">
                    <a href="cart.html" class="default-btn">Add To Cart</a>
                </div>
            </div>
        </div>
    </div>

    <div class="col-lg-4 col-md-6">
        <div class="dishes-item">
            <div class="image">
                <a href="#">
                    <img src="assets/img/dishes/dishes5.jpg" alt="image" />
                </a>
            </div>
        
            <div class="content">
                <h3>
                    <a href="#">Deluxe Veggie</a>
                </h3>
                <span>Lunch</span>
                
                <div class="dishes-btn">
                    <a href="cart.html" class="default-btn">Add To Cart</a>
                </div>
            </div>
        </div>
    </div>

    <div class="col-lg-4 col-md-6">
        <div class="dishes-item">
            <div class="image">
                <a href="#">
                    <img src="assets/img/dishes/dishes3.jpg" alt="image" />
                </a>
            </div>
        
            <div class="content">
                <h3>
                    <a href="#">Peppy Paneer</a>
                </h3>
                <span>Dinner</span>
                
                <div class="dishes-btn">
                    <a href="cart.html" class="default-btn">Add To Cart</a>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
</section>



</>
    );
}

export default About;