import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { emptyCart } from '../reducers/cartSlice';

const ThankYou = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    // Retrieve order ID from session storage
    const orderId = sessionStorage.getItem('orderId');
    // Display the order ID on the page
    document.getElementById('orderId').innerText = orderId;

    // Clear session storage to avoid conflicts with future orders
    sessionStorage.removeItem('orderId');

    // Clear the cart
    dispatch(emptyCart());

    // Redirect to home page after 10 seconds
    const timeoutId = setTimeout(() => {
      navigate('/');
    }, 10000);

    // Clean up the timeout to avoid memory leaks
    return () => clearTimeout(timeoutId);
  }, [navigate, dispatch]);

  return (
    <>
      <section className="checkout-area ptb-100">
        <div className="container">
          <h1>Thank You. Your Order Id is: <span id="orderId"></span></h1>
        </div>
      </section>
    </>
  );
}

export default ThankYou;
