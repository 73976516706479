import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { sendEmail } from '../../reducers/emailSlice';

const Contact = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        subject: '',
        message: ''
    });

    const [formErrors, setFormErrors] = useState({});
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [error, setError] = useState(null);

    const dispatch = useDispatch();

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const errors = validateForm(formData);
        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            return;
        }

        try {
            await dispatch(sendEmail(formData));
            setSubmitSuccess(true);
            setError(null);
            setFormErrors({});
        } catch (err) {
            setSubmitSuccess(false);
            setError(err.message);
        }

        // Clear form after submission if needed
        setFormData({
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            subject: '',
            message: ''
        });
    };

    const validateForm = (formData) => {
        const errors = {};
        if (!formData.firstName.trim()) {
            errors.firstName = 'First Name is required';
        }
        if (!formData.lastName.trim()) {
            errors.lastName = 'Last Name is required';
        }
        if (!formData.email.trim()) {
            errors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            errors.email = 'Invalid email address';
        }
        if (!formData.phoneNumber.trim()) {
            errors.phoneNumber = 'Phone number is required';
        }
        if (!formData.subject.trim()) {
            errors.subject = 'Subject is required';
        }
        if (!formData.message.trim()) {
            errors.message = 'Message is required';
        }
        return errors;
    };

    return (
        <>
            <div className="page-title-area item-bg3">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="container">
                            <div className="page-title-content">
                                <h2>Contact</h2>
                                <ul>
                                    <li>
                                        <a href="index.html">Home</a>
                                    </li>
                                    <li>Contact</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="contact-info-area pt-100 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="contact-info-box mb-30">
                                <div className="icon">
                                    <i className="las la-envelope"></i>
                                </div>
                                <h3>Email Here</h3>
                                <p><a href="email:info@duckkitchen.store"><span className="__cf_email__" data-cfemail="b8d0ddd4d4d7f8ced1c2d796dbd7d5">info@duckkitchen.store</span></a></p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="contact-info-box mb-30">
                                <div className="icon">
                                    <i className="las la-map-marker"></i>
                                </div>
                                <h3>Location Here</h3>
                                <p>New Steer, Main Road Auckland New Zealand</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="contact-info-box mb-30">
                                <div className="icon">
                                    <i className="las la-phone"></i>
                                </div>
                                <h3>Call Here</h3>
                                <p><a href="tel:1234567890">+64 21 123 4567</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="contact-area pb-100">
                <div className="container">
                    <div className="section-title">
                        {submitSuccess ? (
                            <h2>Thank You for contacting us. We will get back to you as soon as possible.</h2>
                        ) : (
                            <>
                                <h2>Please send an enquiry</h2>
                                <p>We have made contact easy. You can contact through any medium such as email, WhatsApp, etc.</p>
                            </>
                        )}
                    </div>
                    <div className="contact-form">
                        <form onSubmit={handleSubmit} id="contactForm">
                            <div className="row">
                                <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                        <label>First Name*</label>
                                        <input type="text" name="firstName" id="firstName" className="form-control" value={formData.firstName} onChange={handleChange} required />
                                        {formErrors.firstName && <div className="error-msg">{formErrors.firstName}</div>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                        <label>Last Name*</label>
                                        <input type="text" name="lastName" id="lastName" className="form-control" value={formData.lastName} onChange={handleChange} required />
                                        {formErrors.lastName && <div className="error-msg">{formErrors.lastName}</div>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                        <label>Your Email*</label>
                                        <input type="email" name="email" id="email" className="form-control" value={formData.email} onChange={handleChange} required />
                                        {formErrors.email && <div className="error-msg">{formErrors.email}</div>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                        <label>Your Phone*</label>
                                        <input type="text" name="phoneNumber" id="phoneNumber" className="form-control" value={formData.phoneNumber} onChange={handleChange} required />
                                        {formErrors.phoneNumber && <div className="error-msg">{formErrors.phoneNumber}</div>}
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                        <label>Your Subject*</label>
                                        <input type="text" name="subject" id="subject" className="form-control" value={formData.subject} onChange={handleChange} required />
                                        {formErrors.subject && <div className="error-msg">{formErrors.subject}</div>}
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                        <label>Your Message*</label>
                                        <textarea name="message" id="message" className="form-control" value={formData.message} onChange={handleChange} required />
                                        {formErrors.message && <div className="error-msg">{formErrors.message}</div>}
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12">
                                    <button type="submit" className="default-btn">
                                        Send Message
                                        <span></span>
                                    </button>
                                    <div id="msgSubmit" className="h3 text-center hidden"></div>
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Contact;
