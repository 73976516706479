import React from 'react';
const Login = () => {
    return (
    <>
    
  
        <div class="page-title-area">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="page-title-content">
                            <h2>Login</h2>
                            <ul>
                                <li>
                                    <a href="index.html">Home</a>
                                </li>
                                <li>Login</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      

        <section class="login-area ptb-100">
            <div class="container">
                <div class="login-form">
                    <div class="sign-in-title">
                        <h3>Login</h3>
                    </div>
                        
                    <form>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Email" />
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Password" />
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input" id="checkme" />
                                    <label class="form-check-label" for="checkme">Keep Me Logged In</label>
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <p class="forgot-password"><a href="#">Forgot Password?</a></p>
                            </div>

                            <div class="col-lg-12">
                                <div class="send-btn">
                                    <button type="submit" class="default-btn">
                                        Login Now
                                        <span></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
      
    </>    
    )
}
    export default Login;