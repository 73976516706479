import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchProducts } from '../reducers/productSlice';

const Shop = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // Fetch products when the component mounts
    dispatch(fetchProducts());
  }, [dispatch]);

  const products = useSelector(state => state.products.products); // Fetch products from Redux store
  const imagePath = useSelector(state => state.global.imagePath); // Assuming 'global' is the key under which your global reducer is stored

  return (
    <>
      <section className="pizza-products-area pt-100 pb-70">
        <div className="container">
          <div className="section-title">
            <h2>Popular Food</h2>
            <p>
              Order delicious meals for timely delivery, fresh and hot! Satisfaction guaranteed.
            </p>
          </div>

          <div className="row">
            {/* Map over the products fetched from the Redux store */}
            {products.map((product, index) => (
              <div className="col-lg-4 col-md-6" key={index}>
                <div className="pizza-products-item">
                  <div className="image">
                    <Link to={`/product/${product._id}`}>
                      {/* Render multiple image elements */}
                      {product.product_images.map((image, imgIndex) => (
                        <img
                          key={imgIndex}
                          src={imagePath + image}
                          alt={`Product ${index + 1}`}
                          style={{ marginRight: '5px' }}
                        />
                      ))}
                    </Link>

                    {/* Render offer if available */}
                    {product.offer && (
                      <div className="offer-text">
                        <h4>{product.offer}%</h4>
                        <span>Off</span>
                      </div>
                    )}
                  </div>
                  <div className="content">
                    {/* Render product name and price */}
                    <h3>
                      <Link to={`/product/${product._id}`}>{product.name}</Link>
                    </h3>
                    <span>${product.price}</span>

                    <div className="products-btn">
                      {/* Render add to cart button */}
                      <Link to={`/product/${product._id}`} className="default-btn">
                        Add To Cart
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Shop;
