import React from 'react';
import { useSelector } from 'react-redux'; // Import useSelector hook from react-redux
import { Link } from 'react-router-dom';
const Privacy = () => {
    return(
<>
       <div class="page-title-area">
   <div class="d-table">
       <div class="d-table-cell">
           <div class="container">
               <div class="page-title-content">
                   <h2>Privacy Policy</h2>
                   <ul>
                       <li>
                           <a href="index.html">Home</a>
                       </li>
                       <li>Privacy Policy</li>
                   </ul>
               </div>
           </div>
       </div>
   </div>
</div>

<section class="terms-of-service-area ptb-100">
   <div class="container">
       <div class="privacy-content">
       <p>DuckKitchen - Privacy Policy</p>
<p>Our privacy commitment</p>
<p>We are committed to being open and transparent about how we manage your personal information.</p>

<p>Our Privacy Policy aims to communicate, in the clearest way possible, how we treat your personal information. We encourage you to read this Privacy Policy carefully. It will help you make informed decisions about sharing your personal information with us.</p>

<p>At DuckKitchen we have a few fundamental principles:</p>

<p>We will always collect, store, use and disclose personal information in accordance with all applicable privacy laws. However, we have also put in place this Privacy Policy to protect personal information you submit, or we collect.</p>
<p>We will only use your personal information when it is necessary for us to deliver you our services or perform other necessary business functions and activities.</p>
<p>We will not use or disclose your personal information for purposes unrelated to our business activities and the services we provide, unless we first obtain your consent.</p>
 

<p>Privacy Policy
This Privacy Policy outlines how we comply with our privacy obligations under applicable laws.</p>

<p>We are bound by the requirements of those laws, which regulate how we may collect, store, use and disclose your personal information. Those laws also specify other requirements, such as how individuals may access, correct and delete personal information held about them.</p>

<p>In this Privacy Policy, when we say "personal information" we mean identifiable information about you (and when an enduring power of attorney is acting on behalf of a resident of our Northbrook retirement villages, the personal information of that resident), such as your name, email, address, telephone number and so on. We may also collect special categories of personal information, depending on the services we provide to you or you require. For example, we collect “health information” in relation to residents and applicants of our Northbrook retirement villages, such as medical history, disabilities, and information about health services or disability services being provided. References in this Privacy Policy to:</p>

<p>"health information" have the meaning given to that term in the Health Information Privacy Code 2020.
“Services” is as defined in Section One of this Privacy Policy.
DuckKItchen, DuckKItchen Transport Limited, DuckKItchen Wines Limited and DuckKItchen Wines Online Limited are part of the DuckKitchen and references in this Privacy Policy to "we", "us", "our" and "DuckKitchen” collectively refers to Winton Land Limited and its subsidiary development entities, related companies and affiliates.</p>

<p>“Website” means any DuckKitchen website.
"you" and "your" refer to individuals who use our Services or provide their personal information to any member of DuckKitchen, and, in the case of our Northbrook retirement villages, includes the authorised personal representative of a resident or applicant.</p>
 

<p>Section One – Your consent
Winton is a publicly listed developer with many large-scale projects currently in progress in New Zealand and Australia, specialising in developing integrated and fully master-planned communities and hospitality precincts that are best by design, with superior building standards. DuckKitchen provides residential, commercial and retirement properties and related services (including on-site medical support from GPs, visiting specialists in our aged care facilities, rest home/hospital level care and secure dementia care), and various products and services in the hospitality industry including restaurants, bars, our group vineyard and online wine sales, and transport ("Services").</p>

<p>Our business relies on the collection of personal information that is submitted by you, or with your authority, to enable us to provide you with our Services. By using our Services or providing your personal information to us, you consent to our collection, storage, use and disclosure of your personal information (including, where applicable, health information) in accordance with this Privacy Policy.</p>

 

<p>Section Two – Privacy Policy changes
We reserve the right to amend this Privacy Policy from time to time and will notify you of any changes by posting an updated version on our Website.  It is your responsibility to check this Privacy Policy periodically for changes, and to keep your email address with us current.</p>

<p>Your continued access and use of our Websites (including online booking, subscription and ordering services on our Website) and/or Services following notification of any changes to this Privacy Policy constitutes acceptance of those changes. If you do not agree with any aspect of the updated Privacy Policy, you must immediately cease all use of our Website and Services and tell us.</p>

 

<p>Section Three – The personal information we collect from you
Information you provide to us directly: Our usual practice is to collect personal information directly from you, when you create an account or online profile, complete any form, including online forms, register to use our Services, make a booking, make a purchase, participate in our contests or surveys, email, call or otherwise communicate with us, or otherwise provide any information in connection with your use of our Services. A few examples include:</p>

<p>Personal details: name(s); contact details; profile pictures.
Demographic information: gender; date of birth; age.
Contact details: telephone number; email address; physical address.
Credit card details for payment of Services (which are stored encrypted or tokenised).</p>

<p>Authorised representatives: copies of any enduring powers of attorney; contact details.
Health information, such as: information about your health (including medical history, prescription details, clinical notes), and health services or disability services currently or previously provided to you.</p>

<p>Personal care and welfare such as: copies of any enduring powers of attorney in relation to personal care and welfare and/or property that you have executed and not revoked at the time of your admission, together with relevant contact details of the appointed attorneys.</p>

<p>The nature of the personal information will of course depend on your relationship with us and which Services you are obtaining from DuckKitchen companies. You can always choose not to provide your personal information to DuckKitchen, but it may mean that we are unable to provide you with the Services.</p>

<p>Information we get from third parties: We may collect or obtain personal information and health information (where applicable) from authorised third parties and as permitted by applicable privacy laws.</p>

<p>Information we collect automatically: We may collect personal information about you automatically when you visit and use the interactive areas and features on our Websites or use our Services, like your IP address, device type access times, language settings and websites you visited before navigating to our Websites. Some of this information may be collected using cookies and similar tracking technologies. We collect your location-based information.  We may use this information to show you where you can find us and will only share this information with our mapping provider for the sole purpose of providing you this service.  You may opt-out of location-based services at any time by disabling location-based services in your browser settings or emailing our Privacy Officer at the email address set out in Section Thirteen below.</p>

<p>Information we create in the performance of the Services:  We may also create or obtain personal information about your interactions with us, and any interactions we have with others on your behalf.</p>

<p>Information you make public: We may collect or obtain your personal information that you manifestly choose to make public, including via online channels such as social media (e.g., LinkedIn, Facebook etc.)</p>

<p>We may combine these types of personal information that we collect about you from these sources.  We do this to improve services we offer you, to improve marketing, analytics, or site functionality.</p>

 

<p>Section Four – How we use the personal information we collect
Where we collect personal information, we will only process it:</p>

<p>to perform a contract with you; or
where we have legitimate interests to process the personal information and these interests are not overridden by your rights; or
in accordance with a legal obligation; or
where we have your consent.</p>

<p>DuckKitchen collects your personal information so that we can provide you with the Services and any related services you may request. In doing so, we may use the personal information we have collected from you for purposes related to the Services. Examples of how we process and use the personal information you have provided to us include:</p>

<p>creating a customer profile for you;
providing you with the information you have requested;
sending news and information about events, activities, offers, promotions, products and services offered by DuckKitchen that we think will be of interest to you;</p>

<p>responding to comments and questions and providing customer service;
fulfilling product orders; and
delivering prizes and completing necessary promotional activities for competition purposes.</p>
<p>We may use the aggregate data generated when you visit any of our Websites or use our Services to conduct analysis of how you interact with our Websites or Services and to improve and develop our Websites and Services.</p>

<p>By using the Services, you consent to your personal information being collected, stored, used and disclosed in this way and for any other use you authorise. DuckKitchen will only use your personal information for purposes described in this Privacy Policy, or with your consent.</p>

 

<p>Section Five – When we may disclose your personal information and who we share your personal information with
Your personal information will not be sold, traded, rented or otherwise provided to others without your consent.</p>

<p>By providing your personal information to any member of the DuckKitchen, you agree to allow us to share that information within the DuckKitchen.  Each of the entities in the DuckKitchen adhere to this Privacy Policy and are contactable via the same details set out in Section Thirteen below.</p>


<p>This Privacy Policy was last updated in November 2024.</p>
       </div>
   </div>
</section>
</>
 );
}

export default Privacy;